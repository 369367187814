import { useStore } from "store/storeUtils";
import { useCallback, useEffect, useState } from "react";
import ReportService from "api/Report";
import {
  GenerateReportResponse,
  ReportBody,
  ReportConfiguration,
  ReportType,
} from "types/report";
import { useTranslation } from "@tecma/i18n";
import { logger } from "@tecma/logs-fe";
import { REPORT_IN_PROGRESS_STATUS } from "constants/report";
import { ADMIN_ROLE } from "constants/role";

const useReports = () => {
  const store = useStore();
  const { t } = useTranslation();
  const reportsIsEnabled =
    store.loggedUser?.role === ADMIN_ROLE && store.brokerFlags?.report;
  const [isLoadingReports, setIsLoadingReports] = useState<boolean>();
  const [isRequestReport, setIsRequestReport] = useState<string>();
  const [configurations, setConfigurations] = useState<
    ReportConfiguration[] | null
  >();

  const handleGetReportTypes = useCallback(async () => {
    try {
      setIsLoadingReports(true);
      const response = await ReportService.getReportConfigurations();
      if (response?.length) {
        setConfigurations(
          response[0].configurations.map((configuration) => ({
            ...configuration,
            details: {
              ...configuration.details,
              movementDateGapDays:
                configuration.details.movementDateGapDays - 1,
            },
          })),
        );
      }
    } catch (e) {
      store.setSnackbar({
        type: "error",
        title: t("iTd.reports.error.title"),
        description: t("iTd.reports.error.description"),
      });
    } finally {
      setIsLoadingReports(false);
    }
  }, [store, t]);

  useEffect(() => {
    if (reportsIsEnabled) {
      handleGetReportTypes();
    }
  }, [reportsIsEnabled, handleGetReportTypes]);

  const handleResponse = (response: GenerateReportResponse) => {
    if (response.redundantReport) {
      if (REPORT_IN_PROGRESS_STATUS.includes(response.status)) {
        return store.setSnackbar({
          type: "warning",
          title: t("iTd.reports.warning.title"),
          description: t(`iTd.reports.warning.InProgress`),
        });
      }
      return store.setSnackbar({
        type: "warning",
        title: t("iTd.reports.warning.title"),
        description: t(`iTd.reports.warning.Pending`),
      });
    }
    return store.setSnackbar({
      type: "success",
      title: t("iTd.reports.success.title"),
      description: t("iTd.reports.success.description"),
    });
  };

  const handleGenerateReport = async (
    reportType: ReportType,
    requestBody: ReportBody,
  ) => {
    try {
      setIsRequestReport(reportType);
      const response = await ReportService.generateReport(
        reportType,
        requestBody,
      );
      if (response?.length) {
        handleResponse(response[0]);
      } else {
        store.setSnackbar({
          type: "error",
          title: t("iTd.reports.error.title"),
          description: t("iTd.reports.error.description"),
        });
      }
    } catch (e) {
      logger.error(e);
      store.setSnackbar({
        type: "error",
        title: t("iTd.reports.error.title"),
        description: t("iTd.reports.error.description"),
      });
    } finally {
      setIsRequestReport(undefined);
    }
  };

  return {
    isLoadingReports,
    isRequestReport,
    isEnabled: reportsIsEnabled,
    configurations,
    generateReport: handleGenerateReport,
  };
};

export default useReports;
