import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "@tecma/i18n";
import Loader from "components/Loader/Loader";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./Dropdown.scss";

type Direction = "top" | "bottom";

// Optional Props
interface DropdownProps {
  title?: string;
  options?: any[];
  value?: any;
  renderValue?: (value: any) => any;
  direction?: Direction;
  onChange?: any;
  isLoading?: any;
  type?: string;
}

// use the optional prop interface to define the default props
const defaultProps: DropdownProps = {
  direction: "bottom",
};

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { direction, options, isLoading, type } = props;
  const { t } = useTranslation();

  return isLoading ? (
    <Loader vAlign="end" hAlign="start" />
  ) : (
    <Select
      className={`dropdown ${type}`}
      {...props}
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        classes: { paper: "dropdown-options" },
        anchorOrigin: {
          vertical: direction === "top" ? "top" : "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: direction === "top" ? "bottom" : "top",
          horizontal: "center",
        },
      }}
    >
      {options?.map((el) => (
        <MenuItem
          key={typeof el === "string" ? el : el.value || el}
          value={typeof el === "string" ? el : el.value || el}
        >
          {typeof el === "string" ? t(`iTd.business.${el}`) : el.value || el}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.defaultProps = defaultProps;

export default React.memo(Dropdown);
