// t(`iTd.business.${item.title}`)
const getApartmentsEconomicStatusPieChartData = (apartments: Array<any>) => {
  const pieChartData: any[] = [
    {
      title: "not booked",
      color: "#B3D4FF",
      number: 0,
      percentage: 0,
      value: 0,
    },
    { title: "booked", color: "#B5BEF0", number: 0, percentage: 0, value: 0 },
  ];
  apartments.forEach((apartment) => {
    let toUpdate;
    switch (apartment.status) {
      case "libero":
      case "interesse":
      case "opzionato":
        toUpdate = pieChartData.find((item) => item.title === "not booked");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "proposta":
      case "compromesso":
      case "rogitato":
        toUpdate = pieChartData.find((item) => item.title === "booked");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      default:
        break;
    }
  });
  let toUpdate = pieChartData.find((item) => item.title === "not booked");
  toUpdate.percentage = `${(
    (toUpdate.number / apartments.length) *
    100
  ).toFixed(1)}%`;
  // toUpdate.value = CurrencyFormatter(undefined, undefined, toUpdate.value);
  toUpdate = pieChartData.find((item) => item.title === "booked");
  // toUpdate.value = CurrencyFormatter(undefined, undefined, toUpdate.value);
  toUpdate.percentage = `${(
    (toUpdate.number / apartments.length) *
    100
  ).toFixed(1)}%`;
  return pieChartData;
};

export default getApartmentsEconomicStatusPieChartData;
