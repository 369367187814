import React from "react";
import classNames from "classnames";
import AppSnackbar from "../AppSnackbar";

// styles
import "./scaffold.scss";

// Required Props
interface ScaffoldRequiredProps {
  children: React.ReactNode;
}

// Optional Props
interface ScaffoldOptionalProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

// Combined required and optional props to build the full prop interface
interface ScaffoldProps extends ScaffoldRequiredProps, ScaffoldOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: ScaffoldOptionalProps = {
  "data-testid": "tecma-scaffold",
};

/**
 * This component is the scaffold of the application. It is used ad Routes wrapper in App component
 * The Scaffold contains the Header component that usually is present in each project page.
 * It could be use to show different pages if the user is authenticated or not.
 * Example:
 * <div> {hideStructure ? children : <header></header><div>{children}</div>}</div>
 */
const Scaffold: React.FC<ScaffoldProps> = ({
  className,
  children,
  ...rest
}) => {
  const classList = classNames("tecma-scaffold", className);
  return (
    <div className={classList} {...rest}>
      {children}
      <AppSnackbar />
    </div>
  );
};

Scaffold.defaultProps = defaultProps;

export default React.memo(Scaffold);
