import React from "react";
import { useTranslation } from "@tecma/i18n";

import "./reports-disabled-card.scss";

const ReportsDisabledCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="reports-disabled-card">
      <div className="reports-disabled-card__title">
        {t("iTd.reports.disabled.title")}
      </div>

      <div className="reports-disabled-card__text">
        {t("iTd.reports.disabled.description")}
      </div>
    </div>
  );
};

export default React.memo(ReportsDisabledCard);
