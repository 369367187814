/* eslint-disable prettier/prettier */
import { memo, useCallback } from "react";
import { useTranslation } from "@tecma/i18n";
import { ReactSVG } from "react-svg";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import {debounce} from "lodash";
import { SEARCH_DEBOUNCE_TIME } from "constants/constants";
import TitleCard from "../../Cards/TitleCard/TitleCard";
import searchIcon from "../../../icons/search.svg";

interface TableToolbarProps {
  title?: string;
  onSearchChanged?: any;
}

const defaultProps: TableToolbarProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
};

const TableToolbar: React.FC<TableToolbarProps> = ({
  title,
  onSearchChanged,
}) => {
  const { t } = useTranslation();

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChanged(event.target.value);
    },
    [],
  );

  return (
    <div className="generic-table-toolbar">
      <TitleCard title={title} />
      <Input
        className="generic-table-search-box"
        placeholder={t("iTd.genericTable.search")}
        disableUnderline
        onChange={debounce(handlePageChange,SEARCH_DEBOUNCE_TIME)}
        endAdornment={
          <InputAdornment position="end">
            <ReactSVG className="generic-table-search-icon" src={searchIcon} />
          </InputAdornment>
        }
      />
    </div>
  );
};

export default memo(TableToolbar);
