import { ReactSVG } from "react-svg";
import React from "react";
import "./customNoResults.scss";

import { useTranslation } from "@tecma/i18n";
import NoData from "../../../icons/noData.svg";

const CustomNoResults = () => {
  const { t } = useTranslation();

  const noResultTitle = t("iTd.table.noResultTitle");
  const noResultSubTitle = t("iTd.table.noResultSubTitle");

  return (
    <div className="customNoResult-container">
      <ReactSVG className="customNoResult-svg" alt="no results" src={NoData} />
      <div className="customNoResult-inside-container">
        <div className="customNoResult-title">{noResultTitle}</div>
        <div className="customNoResult-subtitle">{noResultSubTitle}</div>
      </div>
    </div>
  );
};

export default React.memo(CustomNoResults);
