import moment from "moment";
import { ReactSVG } from "react-svg";
import { CurrencyFormatter } from "../../../utils/CurrencyFormatter";
import Chip from "../../Chip/Chip";

interface CurrencyCellProps {
  value?: any;
  currency?: string;
  locale?: string;
}

export const CurrencyCell: React.FC<CurrencyCellProps> = ({
  value,
  currency,
  locale,
}) => {
  return <span>{CurrencyFormatter(currency, value)}</span>;
};

interface DateCellProps {
  date?: any;
}

export const DateCell: React.FC<DateCellProps> = ({ date }) => {
  return <span>{moment(date).format("L")}</span>;
};

interface IconCellProps {
  iconSrc?: any;
  text?: any;
}

export const IconCell: React.FC<IconCellProps> = ({ iconSrc, text }) => {
  return (
    <div className="icon-cell-container">
      <div className="icon-cell-icon-container">
        <ReactSVG className="icon-cell-icon" src={iconSrc} />
      </div>
      <div className="icon-cell-text">{text}</div>
    </div>
  );
};

interface ChipCellProps {
  label?: any;
  color?: any;
}

export const ChipCell: React.FC<ChipCellProps> = (props) => {
  return (
    <div>
      <Chip {...props} />
    </div>
  );
};

interface ProjectCellProps {
  projects: any[];
  projectId: string;
}

export const ProjectCell: React.FC<ProjectCellProps> = ({
  projects,
  projectId,
}) => {
  const project = projects.find((el) => el.id === projectId);

  return (
    <span className="two-row-line-clamp">
      {project?.displayName || projectId}
    </span>
  );
};

interface NumericCellProps {
  value: number;
}

export const NumericCell: React.FC<NumericCellProps> = ({ value }) => {
  return <span className="two-row-line-clamp">{value}</span>;
};

export const EmptyCell: React.FC = () => {
  return <>-</>;
};

interface TextCellProps {
  text: string;
}

export const TextCell: React.FC<TextCellProps> = ({ text }) => {
  return <span className="two-row-line-clamp">{text}</span>;
};
