import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import useLogout from "customHooks/useLogout";
import paths from "routes/routesPaths";
import { useStore } from "../store/storeUtils";

const ClientComponent = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const { logOut } = useLogout();

  React.useEffect(() => {
    const isLoggedIn = store.brokerId && store.loggedUser;
    if (isLoggedIn) {
      // l'utente è loggato, lo riporto alla pagina richiesta
      const params = new URLSearchParams(window.location.search);
      params.delete("hostname");
      navigate(`${paths.root}?${params}`);
    } else if (!store.checkingJwt) {
      // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
      // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
      // TODO sarebbe meglio avere un identificativo piuttosto che usare il nome...
      const targetTool = store?.demoProjectEnabledTools?.find(
        (e) => e.name === "BusinessPlatform",
      );
      let firstPart;
      let secondPart;
      let finalTargetTool = "";

      if (targetTool) {
        const targetToolSplitted = targetTool.url.split("?");
        const [split0, split1] = targetToolSplitted;

        // se la prima parte non contiene /login
        if (!split0.includes("/login")) {
          // aggiungo al fondo /login
          if (split0.endsWith("/")) {
            firstPart = split0.concat("login");
          } else {
            firstPart = split0.concat("/login");
          }
        } else {
          firstPart = split0;
        }

        // costruisco il backTo
        const backtoUrl = new URL(window.location.href);
        const params = new URLSearchParams(backtoUrl.search);
        params.delete("hostname");
        let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
        backTo = backTo.replace("/login?", "");

        // se la seconda parte esiste
        if (split1) {
          secondPart = split1.concat(`&backTo=${encodeURIComponent(backTo)}`);
          finalTargetTool = `${firstPart}?${secondPart}`;
        } else {
          finalTargetTool = `${firstPart}?backTo=${encodeURIComponent(backTo)}`;
        }
        logOut()
          .then((hasLoggedOut) => {
            if (
              hasLoggedOut &&
              (hasLoggedOut.called || hasLoggedOut.logout === "Ok") &&
              !hasLoggedOut.loading
            ) {
              window.location.href = finalTargetTool;
            }
          })
          .catch((e) => {
            console.error(e);
            window.location.href = finalTargetTool;
          });
      }
    }
    // else, controllo jwt in corso, aspetto
  }, [store.brokerId, store.loggedUser, store.checkingJwt]);

  return null;
});

export default ClientComponent;
