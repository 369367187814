/**
 * a number formatter for currency
 */

import { useStore } from "store/storeUtils";

const CurrencyFormatter = (currency: any, value: any, extraValue = "") => {
  try {
    const store = useStore();
    return new Intl.NumberFormat(store.systemLanguage || "it-IT", {
      style: "currency",
      currency: currency || "EUR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
      .format(value)
      .concat(extraValue);
  } catch (error) {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
      .format(value)
      .concat(extraValue);
  }
};

const bigNumberFormatter = (number: number, digits: number | undefined) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((el) => {
      return number >= el.value;
    });
  return item
    ? {
        number: (number / item.value).toFixed(digits).replace(rx, "$1"),
        symbol: item.symbol,
      }
    : { number: 0 };
};

export { CurrencyFormatter, bigNumberFormatter };
