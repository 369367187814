import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "@tecma/i18n";
import { Theme, useMediaQuery } from "@mui/material";
import { logger } from "@tecma/logs-fe";
import "./templatePage.scss";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import moment from "moment/moment";
import { useMutation } from "@apollo/client";
import convertLanguage from "utils/convertLanguage";
import getOrderedLanguages from "utils/getOrderedLanguage";
import "moment/locale/it";
import "moment/locale/fr";
import "moment/locale/de";
import Navbar from "../../components/common/Navbar/Navbar";
import NavbarMobile from "../../components/common/Navbar/NavbarMobile";

// Optional Props
interface TemplatePageProps {
  BodyComponent?: any;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

// use the optional prop interface to define the default props
const defaultProps: TemplatePageProps = {
  "data-testid": "tecma-templatePage",
};

const TemplatePage: React.FC<TemplatePageProps> = ({
  className,
  BodyComponent,
  ...rest
}) => {
  const store = useStore();
  const classList = classNames("tecma-templatePage", className);
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { i18n, t } = useTranslation();

  const useMobileNavbar = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  const [newLang, setNewLang] = useState("");

  useEffect(() => {
    moment.locale(newLang);
  }, [newLang]);

  const [orderedLanguages, setOrderedLanguages] = useState(
    getOrderedLanguages(
      store.configLanguages,
      convertLanguage(store.loggedUser?.language, store.loggedUser?.locale),
    ),
  );

  const setLanguage = (lang: string, locale: string) => {
    setNewLang(lang);
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    const updateData = {
      TwoFA: store.loggedUser?.TwoFA,
      email: store.loggedUser?.email,
      firstName: store.loggedUser?.firstName,
      hasGoogleToken: store.loggedUser?.hasGoogleToken,
      hasMicrosoftToken: store.loggedUser?.hasMicrosoftToken,
      language: lang,
      lastName: store.loggedUser?.lastName,
      locale,
      project_ids: store.loggedUser?.project_ids,
      role: store.loggedUser?.role,
      vendorColor: store.loggedUser?.vendorColor,
    };
    updateUser(
      Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser?.id, updateData),
    )
      .then(() => {
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
        setNewLang(lang);
      })
      .catch((e) => {
        if (oldLanguage) {
          setNewLang(oldLanguage);
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
          setNewLang(oldLanguage);
        }
        logger.error(e);
      });
  };

  const handleChangeLanguage = (selectedLanguage: string | undefined) => {
    if (selectedLanguage) {
      setOrderedLanguages(
        getOrderedLanguages(store.configLanguages, selectedLanguage),
      );
      const trueLanguage = selectedLanguage.split("-")[0];
      const locale = selectedLanguage.split("-")[1];
      setLanguage(trueLanguage, locale);
    }
  };

  return (
    <div className={classList} {...rest}>
      {useMobileNavbar ? (
        <NavbarMobile handleChangeLanguage={handleChangeLanguage} />
      ) : (
        <Navbar handleChangeLanguage={handleChangeLanguage} />
      )}

      <div id="body-container" className="body-container">
        <BodyComponent />
      </div>
    </div>
  );
};

TemplatePage.defaultProps = defaultProps;

export default React.memo(TemplatePage);
