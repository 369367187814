import React, { useEffect, useState } from "react";
import { useStore } from "store/storeUtils";
import SimplePage from "components/common/SimplePage/SimplePage";
import LeftImageCard, {
  AssetBody,
} from "components/Cards/LeftImageCard/LeftImageCard";
import ExternalLinkButton from "components/Buttons/ExternalLinkButton/ExternalLinkButton";
import GenericButton from "components/Buttons/GenericButton/GenericButton";

import "./assetsManagement.scss";
import ToolsDrawerContent from "components/ToolsDrawerContent/ToolsDrawerContent";
import Drawer from "@mui/material/Drawer";

import { useTranslation } from "@tecma/i18n";
import { observer } from "mobx-react";
import { getBssUrl } from "utils/getProjectUrls";
import { useLazyQuery } from "@apollo/client";
import Client from "gqlclient/Client";
import EmptyCard, {
  SimpleBody,
} from "../../components/Cards/EmptyCard/EmptyCard";
import EmptyStateCard from "../../components/Cards/EmptyStateCard/EmptyStateCard";
import emptyStateAsset from "../../icons/emptyStateAsset.svg";
// Optional Props
interface AssetsManagementProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

// use the optional prop interface to define the default props
const defaultProps: AssetsManagementProps = {};

const AssetsManagement: React.FC<AssetsManagementProps> = observer(() => {
  const store = useStore();
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(undefined);
  const [toolStatusesData, setToolStatusesData] = useState<any>(undefined);
  const [products, setProducts] = useState<any>(undefined);
  const [loading, setLoading] = useState<any>(false);

  const [loadToolStatuses, toolStatuses] = useLazyQuery(
    Client.GET_PROJECT_TOOL_STATUS,
  );

  const [loadAllProducts, allProducts] = useLazyQuery(Client.GET_ALL_PRODUCTS);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedProject(undefined);
    setToolStatusesData(undefined);
  };

  useEffect(() => {
    if (selectedProject && store.demoProjectId) {
      loadToolStatuses(
        Client.GET_PROJECT_TOOL_STATUS_DEFAULT_OPTIONS(selectedProject.id),
      );
      loadAllProducts(
        Client.GET_ALL_PRODUCTS_DEFAULT_OPTIONS(store.demoProjectId),
      );
      setLoading(true);
    }
  }, [selectedProject, store.demoProjectId]);

  useEffect(() => {
    if (
      !toolStatuses.loading &&
      toolStatuses.data &&
      !allProducts.loading &&
      allProducts.data
    ) {
      setToolStatusesData(toolStatuses.data.getProjectToolStatus);
      setProducts(allProducts.data.getAllProducts);
      setLoading(false);
    }
  }, [toolStatuses, allProducts]);

  const retrieveTools = () => {
    if (products) {
      return products.filter((prod: any) => {
        const tool = selectedProject.enabledTools.find(
          (enabledTool: { name: any }) => enabledTool.name === prod.name,
        );
        let isVisible = false;
        // se prodotto è default e abbiamo configurato l'enabledTool con enabled= true o undefined
        if (prod.default === true && tool && tool.enabled !== false)
          isVisible = true;
        // se prodotto NON è default ed abbiamo configurato l'enabledTool con enabled= true o undefined
        else if (tool && tool.enabled !== false) isVisible = true;
        // altrimenti, se il prodotto NON è default e NON è configurato, restituiamo false
        return isVisible;
      });
    }
    return [];
  };

  return (
    <>
      <SimplePage
        title={t("iTd.dashboard.asset")}
        description={t("iTd.asset.description")}
        isLoading={loading}
      >
        {store.emptyState ? (
          <EmptyStateCard
            iconSrc={emptyStateAsset}
            title={t("iTd.emptyState.noAssetTitle", undefined)}
            text={t("iTd.emptyState.text", undefined)}
            ctaLabel={t("iTd.emptyState.cta", undefined)}
          />
        ) : (
          <>
            <div className="asset-list">
              {store.actualProjects &&
                store.actualProjects
                  .filter((project) =>
                    store.actualUserAuthorizedProjectsId.includes(project.id),
                  )
                  .map((project) => {
                    const projectImageSrc = `${
                      process.env.REACT_APP_BUCKET_BASEURL
                    }/initiatives/${
                      project.displayName
                    }/businessplatform/hero-image.jpg?v=${new Date().getDay()}`;
                    return (
                      <LeftImageCard
                        key={project.id}
                        imageSrc={projectImageSrc}
                        isAsset
                      >
                        <AssetBody
                          name={project.displayName}
                          street={project.storeAddress}
                          buttons={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px",
                              }}
                            >
                              <ExternalLinkButton
                                title={t("iTd.dashboard.go")}
                                to={getBssUrl(project)}
                              />
                              <GenericButton
                                onButtonClick={() => {
                                  setOpenDrawer(true);
                                  setSelectedProject(project);
                                }}
                                title={t("iTd.asset.what")}
                              />
                            </div>
                          }
                        />
                      </LeftImageCard>
                    );
                  })}
            </div>
            <div className="new-asset-card">
              <EmptyCard>
                <SimpleBody
                  title={
                    store.actualProjects
                      ? t("iTd.asset.new")
                      : t("iTd.asset.first")
                  }
                  description={t("iTd.asset.if")}
                  buttons={
                    <a href={`mailto:${process.env.REACT_APP_INFO_MAIL}`}>
                      <GenericButton title={t("iTd.asset.contact")} />
                    </a>
                  }
                />
              </EmptyCard>
            </div>
          </>
        )}
      </SimplePage>

      <Drawer
        open={openDrawer && selectedProject && toolStatusesData && products}
        onClose={handleCloseDrawer}
        anchor="right"
      >
        <ToolsDrawerContent
          onCloseClick={handleCloseDrawer}
          title={t("iTd.asset.what")}
          enabledTools={products && selectedProject ? retrieveTools() : []}
          toolStatuses={toolStatusesData}
        />
      </Drawer>
    </>
  );
});

AssetsManagement.defaultProps = defaultProps;

export default React.memo(AssetsManagement);
