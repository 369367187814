import React from "react";
import ReactDOM from "react-dom";
import { injectContextHeaders } from "@tecma/logs-fe";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorPage } from "@tecma/ds";
import { ErrorBoundary } from "react-error-boundary";
import { ApolloProvider } from "@apollo/client";
import Api from "gqlclient/GraphqlClient";
import Store from "store/store";
import { StoreProvider } from "store/storeUtils";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/theme";

// styles
import "./theme/global.scss";

injectContextHeaders();
const store = new Store();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={() => {
        return <ErrorPage logo="" />;
      }}
    >
      <StoreProvider value={store}>
        <ApolloProvider client={Api(store)}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </StoreProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
