import { ACCOUNT_MANAGER_ROLE, ADMIN_ROLE } from "constants/role";
import type { Role } from "types/role";
import { getBrokerUrl } from "./getProjectUrls";

export type SubRoute = {
  titleLabel: string;
  to?: string;
};

export interface NavbarItem {
  icon: string;
  titleLabel: string;
  type: string;
  stickToBottom?: boolean;
  hideMobile?: boolean;
  subRoutes?: SubRoute[];
  to?: string;
  availableToRoles?: Role[];
}

function getNavbarItems(store: any): NavbarItem[] {
  const subRoutes = [
    {
      titleLabel: "recommerce",
      to:
        store.brokers && store.brokerId
          ? getBrokerUrl(store.brokers, store.brokerId)
          : "",
    },
  ];
  if (store?.iTdConfig?.emotionsUrl) {
    subRoutes.push({
      titleLabel: "emotions",
      to: store?.iTdConfig?.emotionsUrl,
    });
  }
  return [
    {
      titleLabel: "dashboard",
      icon: "template",
      type: "link",
      to: "/dashboard",
      stickToBottom: false,
    },
    {
      titleLabel: "businessOverview",
      icon: "presentation-chart-line",
      type: "link",
      to: "/business-overview",
      stickToBottom: false,
    },
    {
      titleLabel: "assetsManagement",
      icon: "office-building",
      type: "link",
      to: "/assets-management",
      stickToBottom: false,
    },
    {
      titleLabel: "enterprise",
      icon: "view-grid",
      type: "routeGroup",
      subRoutes,
    },
    {
      titleLabel: "reports",
      icon: "chart-square-bar",
      type: "link",
      to: "/reports",
      stickToBottom: false,
    },
    {
      titleLabel: "accountManager",
      icon: "users",
      type: "link",
      to: "/account-manager",
      stickToBottom: false,
      availableToRoles: [ADMIN_ROLE, ACCOUNT_MANAGER_ROLE],
    },
    {
      titleLabel: "elearning",
      icon: "bookmark-alt",
      type: "link",
      to: "/e-learning",
      stickToBottom: true,
    },
    {
      titleLabel: "user",
      icon: "user-circle",
      type: "user",
      stickToBottom: true,
      hideMobile: true,
    },
  ];
}

export default getNavbarItems;
