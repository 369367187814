import { useState } from "react";
import { useTranslation } from "@tecma/i18n";
import { useMutation } from "@apollo/client";
import {
  useFormHandler,
  SubmitHandler,
} from "@tecma/ds/lib/utils/hooks/useFormHandler";

import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import type { AccountFields } from "components/AccountManagerDrawer/AccountManagerDrawer";
import ERRORS from "constants/errors";

type UseAccountManagerForm = {
  closeDrawer: () => void;
  defaultValues?: AccountFields;
  userId?: string;
  onSuccess?: () => void;
};

const getErrorName = (error: any) => {
  if (error?.details?.length) {
    return error.details[0].name;
  }
  return error?.name || "";
};

const validateInput = (input: AccountFields, isEdit?: boolean) => {
  return {
    firstName: input.name?.trim(),
    lastName: input.surname?.trim(),
    email: input.email?.trim(),
    language: input.language?.value,
    role: input.role?.value,
    project_ids: input.assets?.map((asset) => asset.value),
    ...((input.phone || isEdit) && {
      tel: input.phone ? input.phone.trim() : null,
    }),
  };
};

const useBackOfficeUserForm = ({
  closeDrawer,
  defaultValues,
  userId,
  onSuccess,
}: UseAccountManagerForm) => {
  const store = useStore();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [createBackOfficeUserITD] = useMutation(
    Client.CREATE_BACK_OFFICE_USER_ITD,
  );
  const [updateBackOfficeUserITD] = useMutation(
    Client.UPDATE_BACK_OFFICE_USER_ITD,
  );

  const {
    register,
    registerWithDefaultValidations,
    handleSubmit,
    errors,
    reset,
    setValue,
    setError,
    clearErrors,
    control,
    getValues,
  } = useFormHandler<AccountFields>({
    mode: "onBlur",
    defaultValues,
  });

  const showErrorSnackbar = (title: string, description: string) => {
    store.setSnackbar({
      type: "error",
      title,
      description,
    });
  };
  const handleErrors = (error: string) => {
    if (
      error === ERRORS.USER.ALREADY_EXISTS.name ||
      error === ERRORS.USER.ALREADY_EXISTS.details[0].name
    ) {
      setError("email", { type: "emailAlreadyExists" });
      return;
    }
    if (
      error === ERRORS.USER.NOT_AUTHORIZED.name ||
      error === ERRORS.AUTH.UNAUTHORIZED.name ||
      error === ERRORS.USER.NOT_ALLOWED.name
    ) {
      showErrorSnackbar(
        t(`iTd.accountManager.error.userNotAuthorized.title`),
        t(`iTd.accountManager.error.userNotAuthorized.description`),
      );
      closeDrawer();
      return;
    }
    if (error === ERRORS.USER.DISABLED.name) {
      showErrorSnackbar(
        t(`iTd.accountManager.error.userDisabled.title`),
        t(`iTd.accountManager.error.userDisabled.description`),
      );
      closeDrawer();
      return;
    }
    showErrorSnackbar(
      t(`iTd.accountManager.error.unexpected.title`),
      t(`iTd.accountManager.error.unexpected.description`),
    );
    closeDrawer();
  };
  const createBackOfficeUser: SubmitHandler<AccountFields> = (formData) => {
    if (Object.keys(errors).length) {
      return;
    }
    try {
      setIsLoading(true);
      createBackOfficeUserITD(
        Client.CREATE_BACK_OFFICE_USER_ITD_DEFAULT_OPTIONS(
          store.demoProject?.id || "",
          validateInput(formData),
        ),
      )
        .then((res) => {
          setIsLoading(false);
          if (res.errors?.length) {
            handleErrors(getErrorName(res.errors[0].extensions?.exception));
          } else if (res.data?.createBackOfficeUserITD) {
            store.setSnackbar({
              type: "success",
              title: t(`iTd.accountManager.success.userCreated.title`),
              description: t(
                `iTd.accountManager.success.userCreated.description`,
              ),
            });
            closeDrawer();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          const graphQLError = error.graphQLErrors[0];
          handleErrors(getErrorName(graphQLError.extensions?.exception));
        });
    } catch (error) {
      setIsLoading(false);
      handleErrors(error as string);
    }
  };
  const updateBackOfficeUser: SubmitHandler<AccountFields> = (formData) => {
    try {
      setIsLoading(true);
      updateBackOfficeUserITD(
        Client.UPDATE_BACK_OFFICE_USER_ITD_DEFAULT_OPTIONS(
          store.demoProject?.id || "",
          { ...validateInput(formData, true), userId },
        ),
      )
        .then((res) => {
          setIsLoading(false);
          if (res.errors?.length) {
            handleErrors(getErrorName(res.errors[0].extensions?.exception));
          } else if (res.data?.updateBackOfficeUserITD) {
            store.setSnackbar({
              type: "success",
              title: t(`iTd.accountManager.success.userEdited.title`),
              description: t(
                `iTd.accountManager.success.userEdited.description`,
              ),
            });
            closeDrawer();
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          const graphQLError = error.graphQLErrors[0];
          handleErrors(getErrorName(graphQLError?.extensions?.exception));
        });
    } catch (error) {
      setIsLoading(false);
      handleErrors(error as string);
    }
  };
  return {
    register,
    registerWithDefaultValidations,
    onCreateUser: handleSubmit(createBackOfficeUser),
    onUpdateUser: handleSubmit(updateBackOfficeUser),
    setValue,
    setError,
    errors,
    isLoading,
    control,
    reset,
    getValues,
    clearErrors,
  };
};
export default useBackOfficeUserForm;
