import React, { useEffect } from "react";
import { Scaffold } from "components/Scaffold";
import { BrowserRouter } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { initI18n, useTranslation } from "@tecma/i18n";
import Box from "@mui/material/Box";
import { useStore } from "store/storeUtils";
import { useLazyQuery, useQuery } from "@apollo/client";
import Client from "gqlclient/Client";
import JwtChecker from "utils/JwtChecker";
import LogoutListener from "components/specific/LogoutListener";
import DetectUrl from "utils/DetectUrl";
import convertLanguage from "utils/convertLanguage";
import moment from "moment";
import inizializeAnalytics, { Ids } from "utils/analytics";
import { Routes } from "./routes";
import translation from "./i18n/translation/en/translation.json";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop";

if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    site: "datadoghq.eu",
    service: "itd",
    env: process.env.REACT_APP_NAMESPACE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

export const i18nInit = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: "iTd",
  apiBase: process.env.REACT_APP_API_URI || "",
  expirationTime: 1000 * 60, // 1 minuto,
  cache: "no-cache",
  debug: false,
});

const tagManagerArgs: Ids = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};
inizializeAnalytics(tagManagerArgs);

const App: React.FC = () => {
  const store = useStore();
  const { i18n } = useTranslation();

  const brokerInfo = useQuery(
    Client.GET_BROKER_INFO,
    Client.GET_BROKER_INFO_DEFAULT_OPTIONS(DetectUrl()),
  );

  if (
    brokerInfo &&
    !brokerInfo.loading &&
    brokerInfo.called &&
    brokerInfo.data &&
    brokerInfo.data.getBrokerInfoByHost
  ) {
    store.setBaseUrl(brokerInfo.data.getBrokerInfoByHost.baseUrl || "/");
    store.setAssetsByObject(brokerInfo.data.getBrokerInfoByHost);
  }

  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  useEffect(() => {
    store.setCheckingJwt(true);
    if (store.brokerId && !userInfo.called) {
      loadUserInfo(
        Client.GET_USER_INFO_DEFAULT_OPTIONS(store?.demoProject?.id, false),
      );
      // Set del nome della pagina sul browser
      document.title = store.name
        ? `Enterprise Platform | ${store.name}`
        : "Enterprise Platform";
    }
  }, [store.brokerId]);

  const userLanguage = userInfo?.data?.getUserByJWT?.language;
  const userLocale = userInfo?.data?.getUserByJWT?.locale;

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUserJwtData({
        userData: userInfo.data.getUserByJWT,
        checkingJwt: false,
      });
      store.setSystemLanguage(userLanguage);

      moment.locale(i18n.language);

      if (
        i18n.language.split("-")[0] !==
          (store.loggedUser?.language || userLanguage) &&
        userLanguage
      ) {
        const convertedLanguage = convertLanguage(userLanguage, userLocale);
        i18n.changeLanguage(convertedLanguage);
        moment.locale(convertedLanguage);
      }
    }
  }, [userLanguage]);

  return (
    <div>
      {store.baseUrl ? (
        <BrowserRouter basename={store.baseUrl}>
          <JwtChecker />
          <ScrollToTop />
          <Scaffold>
            <Routes />
          </Scaffold>
          <LogoutListener />
        </BrowserRouter>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <Loader hAlign="center" vAlign="center" />
        </Box>
      )}
    </div>
  );
};

export default App;
