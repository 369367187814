import React from "react";
import { ReactSVG } from "react-svg";

import "./EmptyStateCard.scss";
import emptyStateGraphic from "../../../icons/emptyStateGraphic.svg";

// Optional Props
interface EmptyStateCardProps {
  iconSrc?: string;
  title?: string;
  text?: string;
  ctaLabel?: string;
}

// use the optional prop interface to define the default props
const defaultProps: EmptyStateCardProps = {
  iconSrc: emptyStateGraphic,
  title: "No data available yet",
  text: "If you need to add a new assets, please",
  ctaLabel: "Contact Support",
};

const EmptyStateCard: React.FC<EmptyStateCardProps> = ({
  iconSrc,
  title,
  text,
  ctaLabel,
}) => {
  return (
    <div className="empty-state-card empty-state-card-root">
      {iconSrc && <ReactSVG className="empty-state-card-icon" src={iconSrc} />}
      {title && <div className="empty-state-card-title">{title}</div>}
      {text && (
        <div className="empty-state-card-text">
          {text}{" "}
          <a
            className="empty-state-card-cta"
            href={`mailto:${process.env.REACT_APP_INFO_MAIL}`}
          >
            {ctaLabel}
          </a>
        </div>
      )}
    </div>
  );
};

EmptyStateCard.defaultProps = defaultProps;

export default React.memo(EmptyStateCard);
