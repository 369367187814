import React, { useState } from "react";
import { useStore } from "store/storeUtils";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

const LogoutListener = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);

  const clearStore = () => {
    try {
      store.logOut();
    } catch (e) {
      console.error(e);
    }
  };

  if (!loggingOut && store.redirectionToSsoNeeded) {
    setLoggingOut(true);
    clearStore();
    navigate("/login");
  }

  return null;
});

export default LogoutListener;
