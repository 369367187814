import { DateTime } from "luxon";

export const generateDateToUTC = (date: DateTime): Date => {
  const day = date.get("day");
  const month = date.get("month");
  const year = date.get("year");
  return new Date(`${month}/${day}/${year} 0:00:00 UTC`);
};

export const formatDate = (date: Date, locale: string) => {
  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(date);
};
