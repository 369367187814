import classNames from "classnames";
import React from "react";

import "./Loader.scss";

type Align = "start" | "center" | "end";

interface LoaderProps {
  hAlign?: Align;
  vAlign?: Align;
}

const defaultProps: LoaderProps = {
  hAlign: "start",
  vAlign: "start",
};

const Loader: React.FC<LoaderProps> = ({ hAlign, vAlign }) => {
  return (
    <div
      className={classNames("loader-container", `h-${hAlign}`, `v-${vAlign}`)}
    >
      <div className="loader-root">
        <span className="loader" />
      </div>
    </div>
  );
};

Loader.defaultProps = defaultProps;

export default React.memo(Loader);
