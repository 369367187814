import { Grid } from "@mui/material";
import { useTranslation } from "@tecma/i18n";
import Loader from "components/Loader/Loader";
import React from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { CurrencyFormatter } from "utils/CurrencyFormatter";
import LegendItem, { LegendContainer } from "../LegendItem/LegendItem";
import "./pieChart.scss";

interface PieBarChartProps {
  title?: string;
  data?: any;
  dataNotAvailable?: boolean;
  loading?: boolean;
}

const noDataColor = "#EEEEEE";

const noData = [
  {
    number: 1,
    color: noDataColor,
  },
];

const legendValue = (props: any) => {
  const { number, percentage, value } = props;

  return value
    ? {
        greyValue: number,
        greyPercentage: percentage,
        value: CurrencyFormatter(undefined, value),
      }
    : {
        greyPercentage: percentage,
        value,
        greyValue: number,
      };
};

// use the optional prop interface to define the default props
const defaultProps: PieBarChartProps = {};

const tooltipComponent = (
  label: string,
  color: string,
  percentage: string,
  amount: string,
) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-first-row">
        <div className="tooltip-dot" style={{ backgroundColor: `${color}` }} />
        <div>{label}</div>
      </div>
      <div className="tooltip-second-row">
        <span>{percentage}</span>
        <span className="tooltip-divisor" />
        <span>{amount}</span>
      </div>
    </div>
  );
};
const CustomTooltip = (dataTooltip: any) => {
  const { active, payload, t } = dataTooltip;
  if (active && payload && payload.length > 0) {
    const label = t(
      `iTd.business.${payload[0].payload.title.toLowerCase()}`,
      payload[0].payload.title,
    );
    const color = payload[0]?.payload?.color;
    const amount = payload[0]?.payload?.value;
    const percentage = payload[0]?.payload?.percentage;
    const number = !amount ? payload[0]?.payload?.number : null;
    return (
      <div>{tooltipComponent(label, color, percentage, number || amount)}</div>
    );
  }
  return null;
};

const PieBarChart: React.FC<PieBarChartProps> = ({
  title,
  data,
  dataNotAvailable,
  loading,
}) => {
  const chartHeight = 167;
  const lineWeight = 16;
  const outerRadius = 167 / 2;
  const innerRadius = 167 / 2 - lineWeight;
  const { t } = useTranslation();
  const dataToUse = dataNotAvailable ? noData : data;

  return (
    <Grid item container className="pie-chart-container" direction="column">
      {title && (
        <Grid item xs="auto">
          <div className="pie-chart-title">{title}</div>
        </Grid>
      )}
      <Grid item xs>
        <Grid
          container
          alignItems={{ xs: "center", md: undefined }}
          sx={{ height: "100%" }}
        >
          <Grid item sm={4} md={12}>
            <ResponsiveContainer width="100%" height={chartHeight}>
              {loading ? (
                <Loader vAlign="center" hAlign="center" />
              ) : (
                <PieChart className="pie-chart">
                  <Pie
                    data={dataToUse}
                    dataKey="number"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={90}
                    endAngle={-270}
                  >
                    {dataToUse?.map((entry: any, index: any) => (
                      <Cell
                        key={`cell-${entry}`}
                        fill={dataToUse[index].color}
                        className="cell"
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    content={<CustomTooltip t={t} />}
                    // allowEscapeViewBox={{ x: true, y: true }}
                  />
                </PieChart>
              )}
            </ResponsiveContainer>
          </Grid>
          <Grid item sm={8} md={12}>
            <LegendContainer className="pie">
              {data?.map((item: any) => (
                <LegendItem
                  key={item.title}
                  title={t(`iTd.business.${item.title.toLowerCase()}`)}
                  color={dataNotAvailable ? noDataColor : item.color}
                  showDataNotAvailable={dataNotAvailable}
                  {...legendValue(item)}
                />
              ))}
            </LegendContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PieBarChart.defaultProps = defaultProps;

export default React.memo(PieBarChart);
