import React from "react";
import { ReactSVG } from "react-svg";
import locationMarker from "../../../icons/locationMarker.svg";
import "./fullImageCard.scss";

// Optional Props
interface FullImageCardProps {
  title?: string;
  // children?: any;
  description?: any;
  imageSrc?: string;
  street?: string;
  button?: any;
}

// use the optional prop interface to define the default props
const defaultProps: FullImageCardProps = {};

const FullImageCard: React.FC<FullImageCardProps> = ({
  title,
  imageSrc,
  description,
  button,
  street,
}) => {
  return (
    <div
      className="full-image-card"
      style={{ backgroundImage: `url('${imageSrc}')` }}
    >
      <div className="text-security-layer" />
      <div className="full-image-text">
        <div className="full-image-card-title">{title}</div>
        {street && (
          <div className="full-image-card-street">
            <ReactSVG src={locationMarker} />
            <div className="full-image-card-description">{street}</div>
          </div>
        )}

        <div className="full-image-card-description">{description}</div>
      </div>
      {button}
    </div>
  );
};

FullImageCard.defaultProps = defaultProps;

export default React.memo(FullImageCard);
