import React from "react";
import "./Chip.scss";

// Optional Props
interface ChipProps {
  label?: string;
  color?: string;
}

// use the optional prop interface to define the default props
const defaultProps: ChipProps = {};

const Chip: React.FC<ChipProps> = ({ label, color }) => {
  return (
    <div className="chip" style={{ backgroundColor: color }}>
      {label}
    </div>
  );
};

Chip.defaultProps = defaultProps;

export default React.memo(Chip);
