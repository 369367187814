import { ObjectFlags } from "typescript";

const getAppointmentsPieChartData = (appointments: any) => {
  const totalAppointments = appointments.find(
    (appointment: any) =>
      appointment.typology === "Appuntamenti Gestiti Totali",
  )?.number;
  const pieChartData: any[] = [
    {
      title: "First Managed",
      color: "#B3D4FF",
      number: appointments.find(
        (appointment: any) =>
          appointment.typology === "Primi Appuntamenti Gestiti",
      )?.number,
      percentage: "",
    },
    {
      title: "Second Managed",
      color: "#B5BEF0",
      number: appointments.find(
        (appointment: any) =>
          appointment.typology === "Secondi Appuntamenti Gestiti",
      )?.number,
      percentage: "",
    },
    {
      title: "Third Managed",
      color: "#C19CE7",
      number: appointments.find(
        (appointment: any) =>
          appointment.typology === "Terzi Appuntamenti Gestiti",
      )?.number,
      percentage: "",
    },
    {
      title: "Others Managed",
      color: "#798CF4",
      number: appointments.find(
        (appointment: any) =>
          appointment.typology === "Altri Appuntamenti Gestiti",
      )?.number,
      percentage: "",
    },
  ];
  const toReturn = pieChartData.map((item) => {
    const percentage = `${((item.number / totalAppointments) * 100).toFixed(
      1,
    )}%`;
    return { ...item, percentage };
  });
  return toReturn;
};

export default getAppointmentsPieChartData;
