import { useKeenSlider } from "keen-slider/react";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@tecma/ds";

import "keen-slider/keen-slider.min.css";
import "./carousel.scss";

interface CarouselProps {
  children: any;
  spacing?: number;
  cardMinWidth?: string;
}

const defaultProps: CarouselProps = {
  children: [],
  spacing: 16,
  cardMinWidth: "40%",
};

const Carousel: React.FC<CarouselProps> = ({ children, spacing }) => {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    rtl: false,
    slides: { perView: "auto", spacing },
  });

  const handlePrevSlide = () => {
    instanceRef.current?.prev();
  };
  const handleNextSlide = () => {
    instanceRef.current?.next();
  };

  return (
    <div className="carousel-container">
      <div className="carousel-command">
        <IconButton
          className="carousel-button"
          onClick={handlePrevSlide}
          disableRipple
        >
          <Icon iconName="chevron-left" />
        </IconButton>
        <IconButton
          className="carousel-button"
          onClick={handleNextSlide}
          disableRipple
        >
          <Icon iconName="chevron-right" />
        </IconButton>
      </div>
      <div ref={sliderRef} className="keen-slider">
        {children.map((el: any) => (
          <div className="keen-slider__slide">{el}</div>
        ))}
      </div>
    </div>
  );
};

Carousel.defaultProps = defaultProps;

export default Carousel;
