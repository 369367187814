import { useState, SyntheticEvent, ReactNode } from "react";

import { Tab, Tabs } from "@mui/material";
import "./TabsNavigation.scss";
import { useTranslation } from "@tecma/i18n";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};
interface TabsNavigationProps {
  children: any[];
}

const defaultProps: TabsNavigationProps = {
  children: [],
};

const TabsNavigation: React.FC<TabsNavigationProps> = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const { t } = useTranslation();
  return (
    <>
      <Tabs
        classes={{ root: "tabs-navigation" }}
        value={currentTab}
        onChange={handleTabChange}
      >
        {children?.length > 0 &&
          children.map((el, i) => {
            const label = el?.props.id;
            return (
              <Tab
                disableRipple
                key={label}
                label={t(`iTd.business.${label}`)}
                id={`tab-${i}`}
              />
            );
          })}
      </Tabs>
      {children?.length > 0 &&
        children.map((el, i) => (
          <TabPanel key={el?.props.id} value={currentTab} index={i}>
            {el}
          </TabPanel>
        ))}
    </>
  );
};

TabsNavigation.defaultProps = defaultProps;

export default TabsNavigation;
