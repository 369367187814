import ColorMap from "../../types/ColorMapType";

const apartmentStatusColorMap: ColorMap = {
  proposta: "#D7DDFC",
  libero: "#E8F2FF",
  compromesso: "#FFEAC8",
  rogitato: "#FFDDD5",
  opzionato: "#ECE1F8",
  interesse: "#E9ECFB",
};

export default apartmentStatusColorMap;
