const getOrderedLanguages = (
  languages: Array<string | undefined>,
  currentLanguage: string | undefined,
) => {
  let orderedLanguages = languages;
  if (orderedLanguages?.includes(currentLanguage)) {
    orderedLanguages = orderedLanguages.filter((l) => l !== currentLanguage);
    orderedLanguages.unshift(currentLanguage);
  }
  return orderedLanguages;
};

export default getOrderedLanguages;
