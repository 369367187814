import React from "react";
import { Grid } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import _ from "lodash";
import { useTranslation } from "@tecma/i18n";
import Loader from "components/Loader/Loader";

import "./stackedBarChart.scss";
import LegendItem, { LegendContainer } from "../LegendItem/LegendItem";

// Optional Props
interface StackedBarChartProps {
  data?: any;
  xAxis?: any;
  legend?: any;
  loading?: boolean;
  dataNotAvailable?: boolean;
  noData?: any;
}

const noDataColor = "#EEEEEE";

// use the optional prop interface to define the default props
const defaultProps: StackedBarChartProps = {};

const CustomizedAxisTick: React.FC<any> = (props: any) => {
  const { x, y, payload, xAxis, t, dataNotAvailable } = props;
  const number = xAxis.find(
    (column: any) => column.name === payload.value,
  )?.value;
  const barName = t(
    `iTd.business.${
      typeof payload.value === "string"
        ? payload.value.toLowerCase()
        : payload.value
    }`,
  );

  return (
    <>
      <text
        x={x}
        y={y + 16}
        textAnchor="middle"
        fontFamily="lato"
        fill="#747B93"
        fontWeight="500"
        fontSize="11px"
      >
        {!barName.includes("iTd.business") ? barName : `${payload.value}`}
      </text>
      <text
        x={x}
        y={y + 30}
        textAnchor="middle"
        fontFamily="lato"
        fill="#464C62"
        fontWeight="500"
        fontSize="11px"
      >
        {dataNotAvailable ? "" : number}
      </text>
    </>
  );
};

const CustomizedYAxisTick: React.FC<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <text
      x={x - 8}
      y={y + 3}
      textAnchor="end"
      fontFamily="lato"
      fill="#9FA7BA"
      fontWeight="500"
      fontSize="11px"
    >
      {payload.value}
    </text>
  );
};

const CustomTooltip = (dataTooltip: any) => {
  const { active, payload, t, dataKey } = dataTooltip;
  if (active && payload && payload.length > 0) {
    const correctPayload = payload.find((el: any) => el.dataKey === dataKey);
    const color = correctPayload?.color;
    const value = correctPayload?.value;
    return (
      <div className="tooltip-container">
        <div className="tooltip-first-row">
          <div
            className="tooltip-dot"
            style={{ backgroundColor: `${color}` }}
          />
          <div>{t(`iTd.business.${dataKey}`, dataKey)}</div>
        </div>
        <div className="tooltip-second-row">
          <span>{value}</span>
        </div>
      </div>
    );
  }
  return null;
};
const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  xAxis,
  legend,
  loading,
  dataNotAvailable,
  noData,
}) => {
  const { t } = useTranslation();
  const [myTooltip, setMyTooltip] = React.useState();
  return (
    <div className="barchart-container">
      {loading ? (
        <div style={{ height: 300, width: "100%" }}>
          <Loader vAlign="center" hAlign="center" />
        </div>
      ) : (
        <Grid container alignItems="center">
          <Grid item xs={7} sm={8} className="chart">
            <ResponsiveContainer width="100%" height={248}>
              <BarChart
                barSize={40}
                data={dataNotAvailable ? noData : data}
                margin={{
                  top: 0,
                  right: 0,
                  left: -32,
                  bottom: 8,
                }}
              >
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tickSize={0}
                  tick={
                    <CustomizedAxisTick
                      data={data}
                      xAxis={xAxis}
                      t={t}
                      dataNotAvailable={dataNotAvailable}
                    />
                  }
                  axisLine={false}
                />
                <YAxis
                  tickSize={0}
                  axisLine={false}
                  tick={<CustomizedYAxisTick />}
                />
                <Tooltip
                  content={<CustomTooltip dataKey={myTooltip} t={t} />}
                  cursor={{ fill: "transparent" }}
                />
                {legend.map((item: any, i: number) => (
                  <Bar
                    key={item.title}
                    dataKey={item.title}
                    stackId="a"
                    fill={dataNotAvailable ? noDataColor : item.color}
                    onMouseEnter={() => {
                      setMyTooltip(item.title);
                    }}
                    stroke="#FFFFFF"
                    strokeWidth={1}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={5} sm={4} className="legend">
            <LegendContainer className="stacked">
              {_.cloneDeep(legend)
                .reverse()
                .map((item: any) => (
                  <LegendItem
                    key={item.title}
                    color={dataNotAvailable ? noDataColor : item.color}
                    title={t(`iTd.business.${item.title}`)}
                    showDataNotAvailable={dataNotAvailable}
                    value={item.value}
                  />
                ))}
            </LegendContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

StackedBarChart.defaultProps = defaultProps;

export default React.memo(StackedBarChart);
