import { ButtonBase } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import externalLink from "../../../icons/externalLink.svg";

import "./externalLinkButton.scss";

// Optional Props
interface ExternalLinkButtonProps {
  title?: string;
  icon?: any;
  to?: string;
  width?: string;
  white?: boolean;
  secondary?: boolean;
}

// use the optional prop interface to define the default props
const defaultProps: ExternalLinkButtonProps = {};

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({
  title,
  icon,
  to,
  width,
  white,
  secondary,
}) => {
  const onButtonClick = (path: string) => {
    window.open(path, "_blank");
  };
  return (
    <ButtonBase
      disableRipple
      className={classNames(
        "external-link-button",
        white && "white",
        secondary && "secondary",
      )}
      style={{ width }}
      onClick={() => {
        if (to) {
          onButtonClick(to);
        }
      }}
    >
      <div className="title">{title}</div>
      <div className="icon">
        <ReactSVG src={externalLink} />
      </div>
    </ButtonBase>
  );
};

ExternalLinkButton.defaultProps = defaultProps;

export default React.memo(ExternalLinkButton);
