import type { OptionSelect } from "@tecma/ds/lib/components/Select/Select";

export const ROLES = {
  ADMIN: {
    label: "Admin",
    value: "admin",
  },
  ACCOUNT_MANAGER: {
    label: "Account Manager",
    value: "account_manager",
  },
  VENDOR: {
    label: "Vendor",
    value: "vendor",
  },
  VENDOR_MANAGER: {
    label: "Vendor Manager",
    value: "vendor_manager",
  },
  PROPRIETA: {
    label: "Visitor",
    value: "proprieta",
  },
  FRONT_OFFICE: {
    label: "Front Office",
    value: "front_office",
  },
  BUILDING_MANAGER: {
    label: "Facility Manager",
    value: "building_manager",
  },
};

export const ADMIN_ROLE = ROLES.ADMIN.value;
export const ACCOUNT_MANAGER_ROLE = ROLES.ACCOUNT_MANAGER.value;
export const VENDOR_ROLE = ROLES.VENDOR.value;
export const VENDOR_MANAGER_ROLE = ROLES.VENDOR_MANAGER.value;
export const PROPRIETA_ROLE = ROLES.PROPRIETA.value;
export const FRONT_OFFICE_ROLE = ROLES.FRONT_OFFICE.value;
export const BUILDING_MANAGER_ROLE = ROLES.BUILDING_MANAGER.value;

export function getRoleByValue(value: string) {
  return (
    (Object.values(ROLES).find(
      (role) => role.value === value,
    ) as OptionSelect) || null
  );
}
