import { useMutation } from "@apollo/client";
import Client from "gqlclient/Client";

const useLogout = () => {
  const [logoutMutation, logoutMutationInfo] = useMutation(
    Client.MUTATION_LOGOUT,
  );

  const logOut = async () => {
    if (logoutMutationInfo && logoutMutationInfo.called) {
      console.log("...trying to log out ...");
      return logoutMutationInfo;
    }
    try {
      console.log("starting log out...");
      const data = (await logoutMutation()).data;
      return data;
    } catch (e) {
      console.error("logout on server error");
      throw e;
    }
  };

  return { logOut };
};

export default useLogout;
