import React from "react";
import moment from "moment";
import "./welcomeCard.scss";

// Optional Props
interface WelcomeCardProps {
  title?: string | React.ReactElement;
}

// use the optional prop interface to define the default props
const defaultProps: WelcomeCardProps = {};

const WelcomeCard: React.FC<WelcomeCardProps> = ({ title }) => {
  const date = moment();
  return (
    <div className="welcome-card">
      <div className="welcome-card-text">{title}</div>
      <div className="welcome-card-date">
        <div className="welcome-card-weekday">{date.format("dddd")}</div>
        <div className="welcome-card-month">{date.format("MMMM")}</div>
        <div className="welcome-card-day">{date.format("DD")}</div>
      </div>
    </div>
  );
};

WelcomeCard.defaultProps = defaultProps;

export default React.memo(WelcomeCard);
