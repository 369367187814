import React, { useCallback } from "react";
import { useTranslation } from "@tecma/i18n";
import { Button } from "@tecma/ds";

import { formatDate } from "utils/date";
import type { BackOfficeUser } from "pages/AccountManager/AccountManager";
import GenericTable from "../common/Table/GenericTable";
import AccountManagerUserDetailPanel from "../AccountManagerUserDetailPanel/AccountMangerUserDetalPanel";

import "./account-manager-table.scss";

interface AccountManagerTableProps {
  tableData: any[];
  loading?: boolean;
  hideActions?: boolean;
  page?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onClickEditAccount?: (fields: BackOfficeUser) => void;
}

const AccountManagerTable: React.FC<AccountManagerTableProps> = ({
  tableData,
  loading,
  hideActions,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onClickEditAccount,
}) => {
  const { i18n, t } = useTranslation();

  const columns = [
    {
      title: t("iTd.accountManager.table.column.account"),
      field: "account",
      render: (rowData: any) => (
        <div>{`${rowData?.firstName || ""} ${rowData?.lastName || ""}`}</div>
      ),
      sorting: true,
    },
    {
      title: t("iTd.accountManager.table.column.email"),
      field: "email",
      render: (rowData: any) => <div>{rowData?.email}</div>,
      sorting: true,
    },
    {
      title: t("iTd.accountManager.table.column.createdOn"),
      field: "createdOn",
      render: (rowData: any) => (
        <div>
          {rowData?.createdOn
            ? formatDate(new Date(rowData?.createdOn), i18n.language)
            : ""}
        </div>
      ),
      sorting: true,
    },
    !hideActions && {
      title: t("iTd.accountManager.table.column.actions"),
      field: "actions",
      render: (rowData: any) => (
        <Button
          onClick={() => {
            if (onClickEditAccount) {
              onClickEditAccount(rowData);
            }
          }}
          color="secondary"
          iconName="pencil"
          size="small"
        >
          {t("iTd.accountManager.edit")}
        </Button>
      ),
    },
  ].filter(Boolean);
  const memoizedDetailPanel = useCallback(
    (rowData: any) => <AccountManagerUserDetailPanel fields={rowData} />,
    [],
  );

  return (
    <div className="account-manager-table">
      <GenericTable
        columns={columns}
        toolbar={false}
        data={tableData.map((data: any) => ({ ...data }))}
        detailPanel={memoizedDetailPanel}
        paging
        page={page}
        pageSize={pageSize}
        pageSizeOptions={[5, 10, 25]}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isLoading={loading}
      />
    </div>
  );
};

export default AccountManagerTable;
