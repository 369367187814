import { ButtonBase } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import internalLink from "../../../icons/internalLink.svg";

import "./internalLinkButton.scss";

// Optional Props
interface InternalLinkButtonProps {
  title?: string;
  to?: string;
}

// use the optional prop interface to define the default props
const defaultProps: InternalLinkButtonProps = {};

const InternalLinkButton: React.FC<InternalLinkButtonProps> = ({
  title,
  to,
}) => {
  const navigate = useNavigate();

  const onButtonClick = (path: string) => {
    navigate(path);
  };
  return (
    <div className="internal-link-button">
      <ButtonBase
        disableRipple
        onClick={() => {
          if (to) {
            onButtonClick(to);
          }
        }}
      >
        <div className="title">{title}</div>
        <ReactSVG className="icon" src={internalLink} />
      </ButtonBase>
    </div>
  );
};

InternalLinkButton.defaultProps = defaultProps;

export default React.memo(InternalLinkButton);
