import React from "react";
import classNames from "classnames";
import { useTranslation } from "@tecma/i18n";
import { useStore } from "store/storeUtils";

// Optional Props
interface HomePageProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

// use the optional prop interface to define the default props
const defaultProps: HomePageProps = {
  "data-testid": "tecma-homePage",
};

const HomePage: React.FC<HomePageProps> = ({ className, ...rest }) => {
  const store = useStore();
  const classList = classNames("tecma-homePage", className);
  const { t } = useTranslation();

  return (
    <div className={classList} {...rest}>
      <div>{t("iTd.brokerList")}</div>
      <ul>
        {store.brokers.map((broker) => (
          <li key={broker.name}>{broker.name}</li>
        ))}
      </ul>
      <div>{t("iTd.projectList")}</div>
      <ul>
        {store.projects.map((project) => (
          <li key={project.name}>{project.name}</li>
        ))}
      </ul>
    </div>
  );
};

HomePage.defaultProps = defaultProps;

export default React.memo(HomePage);
