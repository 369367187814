const getApartmentsEconomicDetailsPieChartData = (apartments: Array<any>) => {
  const pieChartData: any[] = [
    {
      title: "Available",
      color: "#B3D4FF",
      number: 0,
      percentage: 0,
      value: 0,
    },
    { title: "Interest", color: "#B5BEF0", number: 0, percentage: 0, value: 0 },
    {
      title: "Reservation",
      color: "#C19CE7",
      number: 0,
      percentage: 0,
      value: 0,
    },
    { title: "Proposal", color: "#798CF4", number: 0, percentage: 0, value: 0 },
    {
      title: "Settlement",
      color: "#FFB947",
      number: 0,
      percentage: 0,
      value: 0,
    },
    { title: "Sold", color: "#FF8F73", number: 0, percentage: 0, value: 0 },
  ];

  // TODO: migliorare la logica
  apartments.forEach((apartment) => {
    let toUpdate;
    switch (apartment.status) {
      case "libero":
        toUpdate = pieChartData.find((item) => item.title === "Available");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "interesse":
        toUpdate = pieChartData.find((item) => item.title === "Interest");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "opzionato":
        toUpdate = pieChartData.find((item) => item.title === "Reservation");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "proposta":
        toUpdate = pieChartData.find((item) => item.title === "Proposal");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "compromesso":
        toUpdate = pieChartData.find((item) => item.title === "Settlement");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      case "rogitato":
        toUpdate = pieChartData.find((item) => item.title === "Sold");
        toUpdate.number += 1;
        toUpdate.value += apartment.price;
        break;
      default:
        break;
    }
  });

  const response = pieChartData.map((toUpdate) => {
    const percentage = `${((toUpdate.number / apartments.length) * 100).toFixed(
      1,
    )}%`;
    // const value = toUpdate.value;

    return {
      ...toUpdate,
      percentage,
      value: toUpdate.value,
      number: toUpdate.number,
    };
  });

  return response;
};

export default getApartmentsEconomicDetailsPieChartData;
