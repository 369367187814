import { logger } from "@tecma/logs-fe";
import Cookies from "js-cookie";
import {
  Report,
  ReportBody,
  GenerateReportResponse,
  GetReportConfigurationsResponse,
} from "types/report";

class ReportsService {
  baseURL?: string;

  initRequest?: RequestInit;

  constructor() {
    this.baseURL = process.env.REACT_APP_REPORT_API_BASE_URL;
    this.initRequest = {
      headers: {
        referrerPolicy: "unsafe-url",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jwt")}`,
      },
    };
  }

  async getReportConfigurations(): Promise<GetReportConfigurationsResponse | null> {
    try {
      const response = await fetch(`${this.baseURL}configurations`, {
        ...this.initRequest,
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
      return await response.json();
    } catch (err) {
      logger.error("Error on retrieve report types", err);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  async getReport(reportId: string): Promise<Report | null> {
    try {
      const response = await fetch(`${this.baseURL}${reportId}`, {
        ...this.initRequest,
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
      return await response.json();
    } catch (err) {
      logger.error("Error on retrieve report", err);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  async generateReport(
    reportType: string,
    reportBody: ReportBody,
  ): Promise<GenerateReportResponse[] | null> {
    try {
      const response = await fetch(`${this.baseURL}${reportType}`, {
        ...this.initRequest,
        method: "POST",
        body: JSON.stringify(reportBody),
      });
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
      return await response.json();
    } catch (err) {
      logger.error("Error on generate report", err);
    }
    return null;
  }
}

export default new ReportsService();
