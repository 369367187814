const getApartmentsBarChartDataLegend = (
  apartments: Array<any> | undefined,
) => {
  const legend = [
    {
      title: "sold",
      color: "#FF8F73",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.sold,
        0,
      ),
    },
    {
      title: "settlement",
      color: "#FFB947",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.settlement,
        0,
      ),
    },
    {
      title: "proposal",
      color: "#798CF4",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.proposal,
        0,
      ),
    },
    {
      title: "reservation",
      color: "#C19CE7",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.reservation,
        0,
      ),
    },
    {
      title: "interest",
      color: "#B5BEF0",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.interest,
        0,
      ),
    },
    {
      title: "available",
      color: "#B3D4FF",
      value: apartments?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.available,
        0,
      ),
    },
  ];
  return legend;
};

export default getApartmentsBarChartDataLegend;
