import React from "react";
import { useTranslation } from "@tecma/i18n";
import { observer } from "mobx-react";
import SimplePage from "components/common/SimplePage/SimplePage";
import { useStore } from "store/storeUtils";
import BusinessOverviewApartments from "./BusinessOverviewApartments";
import BusinessOverviewClients from "./BusinessOverviewClients";
import TabsNavigation from "../../components/TabsNavigation/TabsNavigation";
import Loader from "../../components/Loader/Loader";

// Optional Props
interface BusinessOverviewProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

// use the optional prop interface to define the default props
const defaultProps: BusinessOverviewProps = {
  "data-testid": "tecma-BusinessOverview",
};

const BusinessOverview: React.FC<BusinessOverviewProps> = observer(() => {
  const { t } = useTranslation();
  const store = useStore();

  const getBusinessOverviewContent = () => {
    if (!store.loggedUser?.id) {
      return (
        <div className="business-overview-loading">
          <Loader hAlign="center" vAlign="center" />
        </div>
      );
    }
    return (
      <TabsNavigation>
        <div id="apartments">
          <BusinessOverviewApartments />
        </div>
        <div id="clients">
          <BusinessOverviewClients />
        </div>
      </TabsNavigation>
    );
  };

  return (
    <SimplePage
      title={t("iTd.navbar.businessOverview")}
      description={t("iTd.business.Description")}
    >
      {getBusinessOverviewContent()}
    </SimplePage>
  );
});

BusinessOverview.defaultProps = defaultProps;

export default React.memo(BusinessOverview);
