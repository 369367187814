import React, { useCallback } from "react";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import { Snackbar } from "@tecma/ds";
import "./app-snackbar.scss";

const AppSnackbar = observer(() => {
  const store = useStore();

  const handleOnClose = useCallback(() => {
    store.closeSnackbar();
  }, [store]);

  return (
    <Snackbar
      handleClose={handleOnClose}
      open={store.snackbar?.open}
      type={store.snackbar?.type}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      title={store.snackbar?.title}
      description={store.snackbar?.description}
    />
  );
});

export default AppSnackbar;
