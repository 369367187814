import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "@tecma/i18n";
import { useStore } from "store/storeUtils";
import StackedBarChart from "components/Charts/StackedBarChart/StackedBarChart";
import { ApolloConsumer, useLazyQuery } from "@apollo/client";
import Client from "gqlclient/Client";
import { Grid, Divider } from "@mui/material";
import _ from "lodash";
import getApartmentsBarChartData from "utils/getApartmentsBarChartData";
import getApartmentsBarChartDataLegend from "utils/getApartmentsBarChartDataLegend";
import getApartmentsEconomicStatusPieChartData from "utils/getApartmentsEconomicStatusPieChartData";
import getApartmentsEconomicDetailsPieChartData from "utils/getApartmentsEconomicDetailsPieChartData";
import noDataFiller from "utils/fillEmptyData";
import house from "icons/house.svg";
import dollar from "icons/dollar.svg";
import check from "icons/check.svg";
import cash from "icons/cash.svg";
import emptyStatePie from "icons/emptyStatePie.svg";
import emptyStateTable from "icons/emptyStateTable.svg";
import { bigNumberFormatter, CurrencyFormatter } from "utils/CurrencyFormatter";
import ValueCard from "components/Cards/ValueCard/ValueCard";
import Dropdown from "components/Inputs/Dropdown/Dropdown";
import NumberCard from "../../components/Cards/NumberCard/NumberCard";
import TitleCard from "../../components/Cards/TitleCard/TitleCard";
import GenericTable from "../../components/common/Table/GenericTable";
import PieChart from "../../components/Charts/PieChart/PieChart";
import {
  ChipCell,
  CurrencyCell,
  DateCell,
  EmptyCell,
  IconCell,
  ProjectCell,
  TextCell,
} from "../../components/common/Table/TableRender";
import apartmentStatusColorMap from "./apartmentStatusColorMap";
import "./BusinessOverview.scss";
import apartmentsNoData from "./apartmentsNoData";
import EmptyStateCard from "../../components/Cards/EmptyStateCard/EmptyStateCard";

// Optional Props
// interface BusinessOverviewApartmentsProps {}

// // use the optional prop interface to define the default props
// const defaultProps: BusinessOverviewApartmentsProps = {};

const optionsView = [
  "apartmentsAdditionalAssets",
  "apartments",
  "additionalAssets",
];

const BusinessOverviewApartments: React.FC = () => {
  const store = useStore();
  const { i18n, t } = useTranslation();

  const [apartmentsBarChartData, setApartmentsBarChartData] = useState<any[]>(
    [],
  );
  const [
    apartmentsEconomicStatusPieChartData,
    setApartmentsEconomicStatusPieChartData,
  ] = useState<any[]>([]);
  const [
    apartmentsEconomicDetailsPieChartData,
    setApartmentsEconomicDetailsPieChartData,
  ] = useState<any[]>([]);
  const [xAxis, setXAxis] = useState<any[]>([]);
  const [legend, setLegend] = useState<any[]>([]);
  const [totalApartments, setTotalApartments] = useState<any>(undefined);
  const [transactionValue, setTransactionValue] = useState<any>(undefined);
  const [transactionCardValue, setTransactionCardValue] = useState(0);
  const [apartmentsSoldCount, setApartmentsSoldCount] =
    useState<any>(undefined);
  const [view, setView] = useState<any>(optionsView[0]);
  const [isVisible, setIsVisible] = useState<any>(true);
  const [dataNotAvailable, setDataNotAvailable] = useState<any>(false);

  const apartmentTableColumns = [
    {
      title: t("iTd.business.apartments"),
      field: "name",
      render: (rowData: any) => (
        <IconCell iconSrc={house} text={rowData.name ? rowData.name : "-"} />
      ),
      searchable: true,
      sorting: true,
    },
    {
      title: t("iTd.business.asset"),
      field: "project_id",
      render: (rowData: any) =>
        rowData.project_id ? (
          <ProjectCell
            projects={[...store.projects]}
            projectId={rowData.project_id}
          />
        ) : (
          <EmptyCell />
        ),
      sorting: true,
      searchable: true,
    },
    {
      title: t("iTd.business.dimensions"),
      field: "typology",
      render: (rowData: any) => {
        if (rowData?.plan?.typology.name) {
          const typology = t(
            `iTd.business.${rowData?.plan?.typology.name.toLowerCase()}`,
            rowData?.plan?.typology.name,
          );
          return <TextCell text={typology} />;
        }
        return <EmptyCell />;
      },
      searchable: false,
      sorting: true,
    },
    {
      title: t("iTd.business.lastUpdate"),
      field: "updatedOn",
      render: (rowData: any) =>
        rowData.updatedOn ? (
          <DateCell date={rowData.updatedOn} />
        ) : (
          <EmptyCell />
        ),
      searchable: false,
      sorting: true,
    },
    {
      title: t("iTd.business.Status"),
      field: "status",
      render: (rowData: any) =>
        rowData.status ? (
          <ChipCell
            label={t(`iTd.business.${rowData.status}`)}
            color={apartmentStatusColorMap[rowData.status]}
          />
        ) : (
          <EmptyCell />
        ),
      searchable: false,
      sorting: true,
    },
    {
      title: t("iTd.business.price"),
      field: "price",
      type: "numeric",
      render: (rowData: any) =>
        rowData.price ? (
          <CurrencyCell
            value={rowData.price}
            currency="EUR"
            locale={i18n.language}
          />
        ) : (
          <EmptyCell />
        ),
      searchable: false,
      sorting: true,
    },
  ];

  const [loadAdditionalAssets, additionalAssets] = useLazyQuery(
    Client.GET_ALL_FEATURES_ITD,
  );

  const [loadApartments, apartments] = useLazyQuery(Client.GET_PROMO_PRICE);

  const [loadApartmentsSold, apartmentsSold] = useLazyQuery(
    Client.GET_APARTMENTS_SOLD_COUNT,
  );

  const [loadApartmentsCount, apartmentsCount] = useLazyQuery(
    Client.GET_APARTMENTS_COUNT,
  );

  const [loadAllTypologiesMultiProject, allTypologiesMultiProject] =
    useLazyQuery(Client.GET_ALL_TYPOLOGIES_MULTI_PROJECT);

  React.useEffect(() => {
    if (!store.emptyState) {
      loadAdditionalAssets(
        Client.GET_ALL_FEATURES_ITD_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadApartments(
        Client.GET_PROMO_PRICE_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadApartmentsSold(
        Client.GET_APARTMENTS_SOLD_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadApartmentsCount(
        Client.GET_APARTMENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadAllTypologiesMultiProject(
        Client.GET_ALL_TYPOLOGIES_MULTI_PROJECT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
    }
  }, [store.emptyState]);

  useEffect(() => {
    let totalApt = 0;
    let totalFts = 0;
    if (apartments.data && apartments.data.getPromoPrice) {
      totalApt = apartments.data.getPromoPrice.reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + currentValue.price,
        0,
      );
    }
    if (additionalAssets.data && additionalAssets.data.getAllFeaturesITd) {
      totalFts = additionalAssets.data.getAllFeaturesITd.elements.reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + currentValue.price,
        0,
      );
    }
    if (apartments.data && apartments.data.getPromoPrice) {
      setTransactionValue(totalApt + totalFts);
    } else {
      setTransactionValue(-1);
    }
  }, [apartments.data, additionalAssets.data]);

  useEffect(() => {
    if (
      apartments.data &&
      additionalAssets.data &&
      allTypologiesMultiProject.data
    ) {
      let apartmentsData = [];
      let additionalAssetsData = [];

      if (apartments.data.getPromoPrice.length > 0) {
        apartmentsData = getApartmentsBarChartData(
          apartments.data.getPromoPrice,
        );

        const allTypologies =
          allTypologiesMultiProject.data.getAllTypologiesMultiProject.elements;

        apartmentsData.forEach((apt) => {
          const index = allTypologies.findIndex(
            (typo: any) => typo.name.toLowerCase() === apt.name,
          );
          if (index > -1) {
            apt.rooms = allTypologies[index].rooms;
          }
        });

        apartmentsData.sort((a, b) =>
          a.rooms > b.rooms ? 1 : b.rooms > a.rooms ? -1 : 0,
        );

        setApartmentsBarChartData(apartmentsData);
        setXAxis(
          apartmentsData.map((data) => {
            return {
              name: data.name,
              value:
                data.sold +
                data.settlement +
                data.proposal +
                data.reservation +
                data.interest +
                data.available,
            };
          }),
        );
        setLegend(getApartmentsBarChartDataLegend(apartmentsData));
      } else {
        setXAxis(
          apartmentsNoData.map((data: any) => {
            return {
              name: data.name,
              value:
                data.sold +
                data.settlement +
                data.proposal +
                data.reservation +
                data.interest +
                data.available,
            };
          }),
        );
        setLegend(getApartmentsBarChartDataLegend(apartmentsNoData));
        setDataNotAvailable(true);
      }

      if (
        additionalAssets.data &&
        additionalAssets.data.getAllFeaturesITd.elements.length > 0
      ) {
        additionalAssetsData = additionalAssets.data.getAllFeaturesITd.elements;
      }

      if (apartmentsData.length === 0 || additionalAssetsData.length === 0) {
        setIsVisible(false);
      }

      switch (view) {
        case optionsView[1]: {
          setApartmentsEconomicStatusPieChartData(
            getApartmentsEconomicStatusPieChartData(
              apartments.data.getPromoPrice,
            ),
          );

          const economicDetailsPieData =
            getApartmentsEconomicDetailsPieChartData(
              apartments.data.getPromoPrice,
            );
          setApartmentsEconomicDetailsPieChartData(economicDetailsPieData);

          if (apartments.data.getPromoPrice.length > 0) {
            const totalPrice = apartments.data.getPromoPrice.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator + currentValue.price,
              0,
            );
            setTransactionCardValue(totalPrice);
          } else {
            setTransactionCardValue(-1);
          }
          break;
        }
        case optionsView[2]: {
          setApartmentsEconomicStatusPieChartData(
            getApartmentsEconomicStatusPieChartData(additionalAssetsData),
          );

          const economicDetailsPieData =
            getApartmentsEconomicDetailsPieChartData(additionalAssetsData);
          setApartmentsEconomicDetailsPieChartData(economicDetailsPieData);

          if (additionalAssetsData.length > 0) {
            const totalPrice =
              additionalAssets.data.getAllFeaturesITd.elements.reduce(
                (accumulator: any, currentValue: any) =>
                  accumulator + currentValue.price,
                0,
              );
            setTransactionCardValue(totalPrice);
          } else {
            setTransactionCardValue(-1);
          }
          break;
        }
        default: {
          setApartmentsEconomicStatusPieChartData(
            getApartmentsEconomicStatusPieChartData(
              apartments.data.getPromoPrice.concat(additionalAssetsData),
            ),
          );

          const economicDetailsPieData =
            getApartmentsEconomicDetailsPieChartData(
              apartments.data.getPromoPrice.concat(additionalAssetsData),
            );
          setApartmentsEconomicDetailsPieChartData(economicDetailsPieData);

          const totalPriceApt = apartments.data.getPromoPrice.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + currentValue.price,
            0,
          );

          const totalPriceAssets = additionalAssetsData.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + currentValue.price,
            0,
          );

          const totalPrice = totalPriceApt + totalPriceAssets;

          if (totalPrice === 0 && apartments.data.getPromoPrice.length === 0) {
            setTransactionCardValue(-1);
          } else {
            setTransactionCardValue(totalPrice);
          }
        }
      }
    }
  }, [apartments.data, additionalAssets.data, view]);

  useEffect(() => {
    if (apartmentsCount.data) {
      setTotalApartments(apartmentsCount.data.getApartmentsCount);
    }
  }, [apartmentsCount.data]);

  useEffect(() => {
    if (apartmentsSold.data) {
      setApartmentsSoldCount(apartmentsSold.data.getApartmentSoldCount);
    }
  }, [apartmentsSold.data]);

  const getData = useCallback(async (query: any, client: any) => {
    let sorting;
    let searching;

    if (query.search) {
      const searchableFields = apartmentTableColumns.filter(
        (el) => el.searchable,
      );
      searching = searchableFields.map((el) => {
        return { key: el.field, value: query.search };
      });
    }
    if (query.orderBy) {
      const sortProperty = apartmentTableColumns.find(
        (el) => el.field === query.orderBy.field,
      );
      if (sortProperty && sortProperty.sorting) {
        sorting = [
          {
            key: sortProperty.field,
            order: query.orderDirection === "asc" ? 1 : -1,
          },
        ];
      }
    }

    const { variables } = Client.GET_ALL_APARTMENTS_ITD_DEFAULT_OPTIONS(
      store.actualUserAuthorizedProjectsId,
      query.page + 1,
      query.pageSize,
      sorting,
      searching,
    );

    const res = await client.query({
      query: Client.GET_ALL_APARTMENTS_ITD,
      variables,
    });

    const { elements, total, page } = res.data.getAllApartmentsITd;
    if (total > 0) {
      return {
        data: _.cloneDeep(elements),
        page: page - 1,
        totalCount: total,
      };
    }
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }, []);

  const handleView = useCallback(
    (event: React.ChangeEvent<unknown>, value: any) => {
      setView(value.props.value);
    },
    [],
  );

  return (
    <Grid container spacing={2}>
      {!store.emptyState && (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <NumberCard
              title={t("iTd.dashboard.apt")}
              icon={house}
              number={totalApartments}
              isLoading={apartmentsCount.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumberCard
              title={t("iTd.dashboard.aptSold")}
              icon={dollar}
              number={apartmentsSoldCount}
              isLoading={apartmentsSold.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumberCard
              title={t("iTd.dashboard.aptAvailable")}
              icon={check}
              number={totalApartments - apartmentsSoldCount}
              isLoading={apartmentsCount.loading || apartmentsSold.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
              isNotValid={Number.isNaN(totalApartments - apartmentsSoldCount)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumberCard
              title={t("iTd.business.value")}
              icon={cash}
              number={bigNumberFormatter(transactionValue, 0).number}
              symbol={bigNumberFormatter(transactionValue, 0).symbol}
              isLoading={apartments.loading || additionalAssets.loading}
              isNotValid={transactionValue === -1}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <div className="card-container">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="card-container">
                <TitleCard
                  title={t(`iTd.business.${view}`)}
                  rightPart={
                    store.emptyState ? null : (
                      <Dropdown
                        renderValue={(value) => (
                          <span>{t(`iTd.business.${value}`)}</span>
                        )}
                        value={view}
                        onChange={handleView}
                        options={optionsView.filter((opt) => opt !== view)}
                        isLoading={
                          apartments.loading || additionalAssets.loading
                        }
                        type="select"
                      />
                    )
                  }
                  isVisible={isVisible}
                />
                {!store.emptyState && (
                  <ValueCard
                    title={t("iTd.business.value")}
                    value={CurrencyFormatter(undefined, transactionCardValue)}
                    isLoading={apartments.loading || additionalAssets.loading}
                    isNotValid={transactionCardValue === -1}
                    dataNotAvailable={t("iTd.dataNotAvailable")}
                  />
                )}
              </div>
            </Grid>
            {store.emptyState ? (
              <EmptyStateCard
                iconSrc={emptyStatePie}
                title={t("iTd.emptyState.noDataTitle", undefined)}
                text={t("iTd.emptyState.text", undefined)}
                ctaLabel={t("iTd.emptyState.cta", undefined)}
              />
            ) : (
              <>
                <Grid item sm={12} md={6} className="economic-status-chart">
                  <PieChart
                    data={apartmentsEconomicStatusPieChartData}
                    title={t("iTd.business.economic")}
                    loading={apartments.loading || additionalAssets.loading}
                    dataNotAvailable={dataNotAvailable}
                  />
                </Grid>
                <div className="padded-flex-separator-mobOrTab">
                  <Divider />
                </div>
                <Grid item sm={12} md={6}>
                  <PieChart
                    data={apartmentsEconomicDetailsPieChartData}
                    title={t("iTd.business.economicDetails")}
                    loading={apartments.loading || additionalAssets.loading}
                    dataNotAvailable={dataNotAvailable}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="card-container">
          <TitleCard title={t("iTd.business.aptStatus")} />
          {store.emptyState ? (
            <EmptyStateCard
              title={t("iTd.emptyState.noDataTitle", undefined)}
              text={t("iTd.emptyState.text", undefined)}
              ctaLabel={t("iTd.emptyState.cta", undefined)}
            />
          ) : (
            <StackedBarChart
              data={apartmentsBarChartData}
              xAxis={xAxis}
              legend={legend}
              loading={apartments.loading}
              noData={apartmentsNoData}
              dataNotAvailable={dataNotAvailable}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="card-container">
          {store.emptyState ? (
            <>
              <TitleCard title={t("iTd.business.last")} />
              <EmptyStateCard
                iconSrc={emptyStateTable}
                title={t("iTd.emptyState.noDataTitle", undefined)}
                text={t("iTd.emptyState.text", undefined)}
                ctaLabel={t("iTd.emptyState.cta", undefined)}
              />
            </>
          ) : (
            <ApolloConsumer>
              {(client) => (
                <GenericTable
                  columns={apartmentTableColumns}
                  title={t("iTd.business.last")}
                  pageSize={5}
                  pageSizeOptions={[5, 10, 20]}
                  search
                  key="name"
                  paging
                  data={(query: any) => getData(query, client)}
                />
              )}
            </ApolloConsumer>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

// BusinessOverview.defaultProps = defaultProps;

export default React.memo(BusinessOverviewApartments);
