type ClientsStatus = {
  lead: number;
  prospect: number;
  client: number;
  callBack: number;
};

const getClientsPieChartData = (clientsStatus: ClientsStatus) => {
  const totalClients =
    clientsStatus.lead +
    clientsStatus.prospect +
    clientsStatus.client +
    clientsStatus.callBack;
  const pieChartData: any[] = [
    {
      title: "Lead",
      color: "#B3D4FF",
      number: clientsStatus.lead,
      percentage: `${((clientsStatus.lead / totalClients) * 100).toFixed(1)}%`,
    },
    {
      title: "Prospect",
      color: "#B5BEF0",
      number: clientsStatus.prospect,
      percentage: `${((clientsStatus.prospect / totalClients) * 100).toFixed(
        1,
      )}%`,
    },
    {
      title: "Client",
      color: "#C19CE7",
      number: clientsStatus.client,
      percentage: `${((clientsStatus.client / totalClients) * 100).toFixed(
        1,
      )}%`,
    },
    {
      title: "Users to call back",
      color: "#798CF4",
      number: clientsStatus.callBack,
      percentage: `${((clientsStatus.callBack / totalClients) * 100).toFixed(
        1,
      )}%`,
    },
  ];

  return pieChartData;
};

export default getClientsPieChartData;
