import ClientComponent from "gqlclient/SSOComponent";
import React from "react";
import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import { TemplatePage } from "views/TemplatePage";
import RouteConsts from "./RouteConsts";

const Routes: React.FC = () => (
  <ReactRoutes>
    {RouteConsts.map((route) => (
      <Route
        key={route.to}
        path={route.to}
        element={<TemplatePage BodyComponent={route.component} />}
      />
    ))}

    <Route path="/login" element={<ClientComponent />} />
    <Route path="/" element={<Navigate replace to="/dashboard" />} />
  </ReactRoutes>
);

export default React.memo(Routes);
