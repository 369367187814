import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Tag } from "@tecma/ds";

import { useStore } from "../../store/storeUtils";
import { getRoleByValue } from "../../constants/role";

import "./account-manager-user-detail-panel.scss";

type AccountManagerUserDetailPanelProps = {
  fields: any;
};

const AccountManagerUserDetailPanel: React.FC<AccountManagerUserDetailPanelProps> =
  observer(({ fields }) => {
    const { t } = useTranslation();
    const store = useStore();

    const getAssetLabel = (assetId: string) => {
      return (
        store.projects.find((project: any) => project.id === assetId)
          ?.displayName || ""
      );
    };
    return (
      <div className="account-manager-user-detail-panel">
        <div className="property">
          <p className="label">{t("iTd.accountManager.field.phone.title")}</p>
          <p className="value">{fields?.tel || "-"}</p>
        </div>
        <div className="property">
          <p className="label">
            {t("iTd.accountManager.field.language.title")}
          </p>
          <p className="value">
            {fields?.notificationLanguage
              ? t(`iTd.language.${fields.notificationLanguage}`)
              : "-"}
          </p>
        </div>
        <div className="property">
          <p className="label">{t("iTd.accountManager.field.role.title")}</p>
          <p className="value">
            {fields?.role ? getRoleByValue(fields.role).label : "-"}
          </p>
        </div>
        <div className="property">
          <p className="label">{t("iTd.accountManager.field.assets.title")}</p>
          <p className="value list-values">
            {fields?.project_ids?.length
              ? fields.project_ids.map((assetId: any) => (
                  <Tag key={assetId} label={getAssetLabel(assetId)} />
                ))
              : "-"}
          </p>
        </div>
      </div>
    );
  });

export default React.memo(AccountManagerUserDetailPanel);
