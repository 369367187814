import React from "react";
import { ReactSVG } from "react-svg";

import "./leftImageCard.scss";
import location from "../../../icons/location.svg";

// Optional Props
interface LeftImageCardProps {
  children?: any;
  imageSrc?: string;
  isAsset?: boolean;
}

// use the optional prop interface to define the default props
const defaultProps: LeftImageCardProps = {};

const LeftImageCard: React.FC<LeftImageCardProps> = ({
  imageSrc,
  children,
  isAsset,
}) => {
  return (
    <div
      className={`${
        isAsset ? "left-image-card-asset-height" : "left-image-card-home-height"
      } left-image-card`}
    >
      <div
        className="left-image-card-media"
        style={{ backgroundImage: `url('${imageSrc}')` }}
      />
      {children}
    </div>
  );
};

LeftImageCard.defaultProps = defaultProps;

interface SimpleBodyProps {
  title?: string;
  description?: string;
  buttons?: any;
}

export const SimpleBody: React.FC<SimpleBodyProps> = ({
  title,
  description,
  buttons,
}) => {
  return (
    <div className="left-image-card-body simple-body">
      <div>
        <div className="left-image-card-title">{title}</div>
        <div className="left-image-card-description ">{description}</div>
      </div>
      {buttons}
    </div>
  );
};
interface AssetBodyProps {
  name?: string;
  street?: string;
  description?: string;
  buttons?: any;
}

export const AssetBody: React.FC<AssetBodyProps> = ({
  name,
  street,
  description,
  buttons,
}) => {
  return (
    <div className="left-image-card-body asset-body">
      <div>
        <div className="left-image-card-title">{name}</div>
        <div className="left-image-card-street">
          <ReactSVG className="left-image-card-street-icon" src={location} />
          <div className="left-image-card-street-text">{street}</div>
        </div>
        <div className="left-image-card-description">{description}</div>
      </div>
      {buttons}
    </div>
  );
};

export default React.memo(LeftImageCard);
