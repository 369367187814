import Home from "pages/Home";
import Dashboard from "pages/Dashboard/Dashboard";
import BusinessOverview from "pages/BusinessOverview/BusinessOverview";
import AssetsManagement from "pages/AssetsManagement/AssetsManagement";
import eLearning from "pages/Elearning/eLearning";
import Reports from "pages/Reports/Reports";
import AccountManager from "pages/AccountManager/AccountManager";

export const HomeRoute = {
  id: "home",
  order: 0,
  exact: true,
  to: "/",
  title: "title.dashboard",
  navbarTitle: "navbarTitle.dashboard",
  navbar: true,
  goBack: false,
  component: Home,
};

export const DashboardRoute = {
  id: "dashboard",
  order: 0,
  exact: true,
  to: "/dashboard",
  title: "title.dashboard",
  navbarTitle: "navbarTitle.dashboard",
  navbar: true,
  goBack: false,
  component: Dashboard,
};

export const BusinessOverviewRoute = {
  id: "businessOverview",
  order: 0,
  exact: true,
  to: "/business-overview",
  title: "title.businessOverview",
  navbarTitle: "navbarTitle.businessOverview",
  navbar: true,
  goBack: false,
  component: BusinessOverview,
};

export const AssetsManagementRoute = {
  id: "assetsManagement",
  order: 0,
  exact: true,
  to: "/assets-management",
  title: "title.assetsManagement",
  navbarTitle: "navbarTitle.assetsManagement",
  navbar: true,
  goBack: false,
  component: AssetsManagement,
};

export const ReportsRoute = {
  id: "reports",
  order: 0,
  exact: true,
  to: "/reports",
  title: "title.reports",
  navbarTitle: "navbarTitle.reports",
  navbar: true,
  goBack: false,
  component: Reports,
};

export const AccountManagerRoute = {
  id: "accountManager",
  order: 0,
  exact: true,
  to: "/account-manager",
  title: "title.accountManager",
  navbarTitle: "navbarTitle.accountManager",
  navbar: true,
  goBack: false,
  component: AccountManager,
};

export const ELearningRoute = {
  id: "eLearning",
  order: 0,
  exact: true,
  to: "/e-learning",
  title: "title.eLearning",
  navbarTitle: "navbarTitle.eLearning",
  navbar: true,
  goBack: false,
  component: eLearning,
};

const RouteConsts = [
  DashboardRoute,
  BusinessOverviewRoute,
  AssetsManagementRoute,
  ReportsRoute,
  AccountManagerRoute,
  ELearningRoute,
];
export default RouteConsts;
