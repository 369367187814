import React from "react";
import { useStore } from "store/storeUtils";
import SimplePage from "components/common/SimplePage/SimplePage";
import FullImageCard from "components/Cards/FullImageCard/FullImageCard";
import ExternalLinkButton from "components/Buttons/ExternalLinkButton/ExternalLinkButton";
import Grid from "@mui/material/Grid";
import { useTranslation } from "@tecma/i18n";
import { eLearningTiles } from "../../constants/eLearningTiles";

import "./Elearning.scss";

// Optional Props
interface ELearningProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

// use the optional prop interface to define the default props
const defaultProps: ELearningProps = {};

const ELearning: React.FC<ELearningProps> = () => {
  const store = useStore();
  const { t } = useTranslation();
  const demoProject = store.demoProject;

  return (
    <SimplePage
      title={t("iTd.navbar.elearning")}
      description={t("iTd.elearning.description")}
    >
      <Grid container spacing={2}>
        {eLearningTiles(demoProject).map((tile) => (
          <Grid item xs={12} md={6} key={tile.key} className="e-learning-card">
            <FullImageCard
              title={t(`iTd.dashboard.${tile.key}.title`)}
              description={t(`iTd.dashboard.${tile.key}.description`)}
              imageSrc={tile.image}
              button={
                <ExternalLinkButton
                  white
                  title={t(`iTd.dashboard.${tile.key}.buttonText`)}
                  to={tile.url}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </SimplePage>
  );
};

ELearning.defaultProps = defaultProps;

export default React.memo(ELearning);
