import { createTheme } from "@mui/material/styles";

declare module "@mui/system" {
  interface BreakpointOverrides {
    // Example BreakPoint for Tablet Portrait (added):
    // tp: true;
    // Default breakpoints:
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      // example of breakpoint to add:
      // tp: 768
      xs: 0,
      sm: 320,
      md: 1025,
      lg: 1441,
      xl: 1921,
    },
  },
});

export default theme;
