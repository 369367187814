import { useTranslation } from "@tecma/i18n";
import classNames from "classnames";
import React from "react";
import "./legendItem.scss";

// Optional Props
interface LegendContainerProps {
  children?: any;
  className?: string;
}

export const LegendContainer: React.FC<LegendContainerProps> = (props) => {
  const { children, className } = props;

  return (
    <div className={classNames("legend-container", className)}>{children}</div>
  );
};

// Optional Props
interface LegendItemProps {
  color?: string;
  title?: string;
  greyValue?: any;
  greyPercentage?: any;
  value?: any;
  showDataNotAvailable?: boolean;
}

// use the optional prop interface to define the default props
const defaultProps: LegendItemProps = {};

const LegendItem: React.FC<LegendItemProps> = ({
  title,
  color,
  greyValue,
  greyPercentage,
  value,
  showDataNotAvailable,
}) => {
  const { t } = useTranslation();

  return (
    <div className="legend-item">
      <div>
        <div
          className="legend-circle-icon"
          style={{ backgroundColor: color }}
        />
      </div>
      <div className="title">{title}</div>

      {showDataNotAvailable ? (
        <div>{t("iTd.dataNotAvailable")}</div>
      ) : (
        <>
          {greyValue || greyValue === 0 ? (
            <div className="number">{greyValue}</div>
          ) : (
            ""
          )}
          {greyPercentage || greyPercentage === 0 ? (
            <div className="percentage">{greyPercentage}</div>
          ) : (
            ""
          )}
          {value || value === 0 ? <div className="value">{value}</div> : ""}
        </>
      )}
    </div>
  );
};

LegendItem.defaultProps = defaultProps;

export default React.memo(LegendItem);
