import { logger } from "@tecma/logs-fe";
import classNames from "classnames";
import React, { useState } from "react";
import { useStore } from "store/storeUtils";
import Loader from "../../Loader/Loader";

import "./SimplePage.scss";

// Optional Props
interface SimplePageProps {
  title?: string;
  children: JSX.Element | JSX.Element[] | null;
  description?: string;
  isLoading?: boolean;
}

const SimplePage: React.FC<SimplePageProps> = ({
  title,
  description,
  children,
  isLoading,
}) => {
  const store = useStore();
  const [isLogoInError, setIsLogoInError] = useState(false);

  const logoOnError = () => {
    logger.error("⚠️ Logo Broker NOT found.");
    setIsLogoInError(true);
  };

  return (
    <div className={`SimplePage-container ${title}`}>
      {isLoading ? (
        <Loader />
      ) : (
        <img
          className={classNames("logo", isLogoInError && "logo-error")}
          alt="logo"
          src={`${process.env.REACT_APP_BUCKET_BASEURL}/brokers/${store.brokerName}/itd/logo.png`}
          onError={logoOnError}
        />
      )}
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      {children}
    </div>
  );
};

export default React.memo(SimplePage);
