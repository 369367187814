import ButtonBase from "@mui/material/ButtonBase";
import React, { useEffect, useState } from "react";
import { Icon } from "@tecma/ds";
import "./toolsDrawerContent.scss";
import ToolsDrawerItem from "./ToolsDrawerItem";

// Optional Props
interface ToolsDrawerContentProps {
  // "data-testid"?: string;
  title?: string;
  enabledTools?: Array<any>;
  toolStatuses?: Array<any>;
  onCloseClick?: any;
}

const ToolsDrawerContent: React.FC<ToolsDrawerContentProps> = ({
  title,
  enabledTools,
  toolStatuses,
  onCloseClick,
}) => {
  const [toolWithStatuses, setToolWithStatuses] = useState<Array<any>>([]);

  useEffect(() => {
    const statuses = enabledTools?.map((tool: { status: any; name: any }) => {
      const toolStatus = toolStatuses?.find(
        (toolStat: any) => tool.name === toolStat.toolName,
      );
      return {
        name: tool?.name,
        status: toolStatus?.status,
      };
    });
    if (statuses) setToolWithStatuses(statuses);
  }, [enabledTools, toolStatuses]);

  return (
    <div className="tools-drawer-content">
      <div className="header">
        <div className="title">{title}</div>
        <ButtonBase
          disableRipple
          onClick={() => {
            onCloseClick();
          }}
        >
          <Icon iconName="close" />
        </ButtonBase>
      </div>
      {toolWithStatuses?.map((tool) => (
        <ToolsDrawerItem
          title={tool.name}
          status={tool.status ? tool.status : "-"}
        />
      ))}
    </div>
  );
};

export default React.memo(ToolsDrawerContent);
