import { ButtonBase } from "@mui/material";
import React from "react";
import "./genericButton.scss";

// Optional Props
interface GenericButtonProps {
  title?: string;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

// use the optional prop interface to define the default props
const defaultProps: GenericButtonProps = {};

const GenericButton: React.FC<GenericButtonProps> = ({
  title,
  onButtonClick,
}) => {
  return (
    <ButtonBase
      disableRipple
      className="generic-button"
      onClick={onButtonClick}
    >
      <div className="title">{title}</div>
    </ButtonBase>
  );
};

GenericButton.defaultProps = defaultProps;

export default React.memo(GenericButton);
