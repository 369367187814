import { DateRangePicker, Select } from "@tecma/ds";
import React from "react";
import { useTranslation } from "@tecma/i18n";
import { DateTime } from "luxon";
import { ReportConfigurationDetails, ReportFiltersInput } from "types/report";
import { useStore } from "store/storeUtils";
import "./report-filters.scss";

interface ReportFiltersProps {
  showFilters: boolean;
  currentFilters: ReportFiltersInput;
  filtersConfig: ReportConfigurationDetails;
  onUpdateFilters: (filters: Partial<ReportFiltersInput>) => void;
  isSelectEmpty: boolean;
  setIsSelectEmpty: (isEmpty: boolean) => void;
}

const ReportFilters: React.FC<ReportFiltersProps> = ({
  filtersConfig,
  showFilters,
  currentFilters,
  onUpdateFilters,
  isSelectEmpty,
  setIsSelectEmpty,
}) => {
  const store = useStore();
  const { t, i18n } = useTranslation();
  const { movementFilters, projects } = currentFilters;

  const handleOnChangeDates = (val: any) => {
    onUpdateFilters({ movementFilters: val });
  };

  const handleOnChangeProjects = (val: any) => {
    onUpdateFilters({ projects: val });
    if (isSelectEmpty) {
      setIsSelectEmpty(false);
    }
  };

  const disabledDate = (
    currentDate: DateTime,
    info: {
      type: "time" | "date" | "week" | "month" | "quarter" | "year" | "decade";
      from?: DateTime;
    },
  ) => {
    if (currentDate > DateTime.now().endOf("day")) {
      return true;
    }
    if (info.from) {
      return (
        Math.abs(currentDate.diff(info.from, "days").as("days")) >
        filtersConfig.movementDateGapDays
      );
    }
    return false;
  };

  return (
    <div className={`report-filters ${showFilters ? "open" : ""}`}>
      <DateRangePicker
        label={t("iTd.reports.rangePicker.label")}
        allowClear={false}
        disabledDate={disabledDate as any}
        onChange={handleOnChangeDates}
        placement="bottomLeft"
        value={movementFilters as any}
        localeLang={i18n?.language}
      />
      <Select
        isMulti
        value={projects}
        isRequired
        error={isSelectEmpty}
        helpText={t("iTd.reports.initiative.required")}
        isLoading={!store.projectsFilterOptions?.length}
        options={store.projectsFilterOptions ?? []}
        placeholder={t("iTd.reports.initiative.placeholder")}
        label={t("iTd.reports.initiative.label")}
        noOptionsMessage={t("iTd.reports.initiative.noOptions")}
        searchPlaceholder={t("iTd.reports.initiative.search")}
        selectAllLabel={t("iTd.reports.initiative.selectAll")}
        deselectAllLabel={t("iTd.reports.initiative.deselectAll")}
        onChange={handleOnChangeProjects}
        showSelectedItemRemoveIcon={false}
      />
    </div>
  );
};

export default ReportFilters;
