import { ObjectFlags } from "typescript";

const getApartmentsBarChartData = (apartments: Array<any>) => {
  const barChartData: any[] = [];
  apartments.forEach((apartment) => {
    if (
      barChartData.filter(
        (data) => data.name === apartment.typology.name.toLowerCase(),
      ).length === 0
    ) {
      barChartData.push({
        name: apartment.typology.name.toLowerCase(),
        sold: 0,
        settlement: 0,
        proposal: 0,
        reservation: 0,
        interest: 0,
        available: 0,
      });
    }
    const entryToModify = barChartData.find(
      (data: any) => data.name === apartment.typology.name.toLowerCase(),
    );
    switch (apartment.status) {
      case "libero":
        entryToModify.available += 1;
        break;
      case "interesse":
        entryToModify.interest += 1;
        break;
      case "opzionato":
        entryToModify.reservation += 1;
        break;
      case "proposta":
        entryToModify.proposal += 1;
        break;
      case "compromesso":
        entryToModify.settlement += 1;
        break;
      case "rogitato":
        entryToModify.sold += 1;
        break;
      default:
        break;
    }
  });
  return barChartData;
};

export default getApartmentsBarChartData;
