import Loader from "components/Loader/Loader";
import React from "react";
import "./ValueCard.scss";

// Optional Props
interface ValueCardProps {
  title?: string;
  value?: string;
  isLoading?: boolean;
  isNotValid?: boolean;
  dataNotAvailable?: string;
}

// use the optional prop interface to define the default props
const defaultProps: ValueCardProps = {};

const ValueCard: React.FC<ValueCardProps> = ({
  title,
  value,
  isLoading,
  isNotValid,
  dataNotAvailable,
}) => {
  return (
    <div className="value-card">
      <div className="value-card-content">
        <div className="value-card-content-title">{title}</div>

        <div className="value-card-content-value">
          {isLoading ? (
            <Loader vAlign="end" hAlign="start" />
          ) : isNotValid ? (
            <span className="nodata">{dataNotAvailable}</span>
          ) : (
            <div>{value}</div>
          )}
        </div>
      </div>
    </div>
  );
};

ValueCard.defaultProps = defaultProps;

export default React.memo(ValueCard);
