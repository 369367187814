import React from "react";
import { ReactSVG } from "react-svg";
import info from "icons/info.svg";
import Loader from "../../Loader/Loader";

import "./numberCard.scss";

// Optional Props
interface NumberCardProps {
  title?: string;
  children?: any;
  number?: number | string | undefined;
  symbol?: string;
  icon?: any;
  isLoading?: boolean;
  isNotValid?: boolean;
  dataNotAvailable?: string;
}

// use the optional prop interface to define the default props
const defaultProps: NumberCardProps = {};

interface ContentProps {
  number?: number | string | undefined;
  symbol?: string;
  children?: any;
  isNotValid?: boolean;
  dataNotAvailable?: string;
}

const defaultContentProps: ContentProps = {};

const CardContent: React.FC<ContentProps> = ({
  number,
  symbol,
  children,
  isNotValid,
  dataNotAvailable,
}) => {
  return (
    <>
      {typeof number !== "undefined" && !isNotValid ? (
        <div className="number-card-number">
          {number} {symbol && <span className="symbol">{symbol}</span>}
        </div>
      ) : (
        <span className="nodata">
          {dataNotAvailable}
          <ReactSVG className="icon" src={info} />
        </span>
      )}
      {children}
    </>
  );
};

CardContent.defaultProps = defaultContentProps;

const NumberCard: React.FC<NumberCardProps> = ({
  title,
  number,
  symbol,
  icon,
  children,
  isLoading,
  isNotValid = false,
  dataNotAvailable,
}) => {
  return (
    <div className="number-card">
      <div className="number-card-header">
        <div className="number-card-title">{title}</div>
        <ReactSVG className="number-card-icon" src={icon} />
      </div>
      {isLoading ? (
        <Loader vAlign="end" hAlign="start" />
      ) : (
        <CardContent
          number={number}
          symbol={symbol}
          isNotValid={isNotValid}
          dataNotAvailable={dataNotAvailable}
        >
          {children}
        </CardContent>
      )}
    </div>
  );
};

NumberCard.defaultProps = defaultProps;

export default React.memo(NumberCard);
