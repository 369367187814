import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Card, Icon, Spinner } from "@tecma/ds";
import { DateTime } from "luxon";
import {
  ReportBody,
  ReportConfiguration,
  ReportFiltersInput,
  ReportType,
} from "types/report";
import ReportFilters from "components/ReportFilters";
import { generateDateToUTC } from "utils/date";
import { useStore } from "store/storeUtils";
import "./report-card.scss";

interface ReportCardsProps {
  isRequestReport?: string;
  configuration: ReportConfiguration;
  onGenerateReport: (
    reportType: ReportType,
    requestBody: ReportBody,
  ) => Promise<void>;
}

const ReportCard: React.FC<ReportCardsProps> = ({
  configuration,
  isRequestReport,
  onGenerateReport,
}) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const store = useStore();

  const [isSelectEmpty, setIsSelectEmpty] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<ReportFiltersInput>({
    projects: store.projectsFilterOptions ?? [],
    movementFilters: [
      DateTime.now().minus({
        days: configuration?.details?.movementDateGapDays,
      }),
      DateTime.now(),
    ],
  });

  useEffect(() => {
    if (store.projectsFilterOptions?.length) {
      setCurrentFilters((curr) => ({
        ...curr,
        projects: store.projectsFilterOptions ?? [],
      }));
    }
  }, [store.projectsFilterOptions]);

  const handleOnGenerateReport = () => {
    if (currentFilters.projects.length) {
      if (isSelectEmpty) {
        setIsSelectEmpty(false);
      }
      onGenerateReport(configuration.reportType, {
        projects: currentFilters.projects.map((project) => project.value),
        exportFormat: "csv",
        extraInputParam: {
          inputFields: [],
          movementFilters: {
            dateFrom: generateDateToUTC(currentFilters.movementFilters[0]),
            dateTo: generateDateToUTC(currentFilters.movementFilters[1]),
          },
        },
      });
    } else {
      setIsSelectEmpty(true);
    }
  };

  const handleOnUpdateFilters = (filters: Partial<ReportFiltersInput>) => {
    setCurrentFilters((curr) => ({ ...curr, ...filters }));
  };

  const isDisabledCreateReport = () => {
    return (
      isRequestReport === configuration.reportType ||
      !store.projectsFilterOptions?.length
    );
  };

  return (
    <Card className="report-card" fluid>
      <Card.Header>
        <Icon
          className="report-card__icon"
          size="large"
          iconName="document-download"
        />
      </Card.Header>
      <Card.Content className="report-card__content">
        <p className="report-card__title">
          {t(`iTd.reports.${configuration.reportType}.title`)}
        </p>
        <p className="report-card__description">
          {t(`iTd.reports.${configuration.reportType}.description`)}
        </p>
      </Card.Content>
      <Card.Footer className="report-card__footer">
        <div className="report-card__buttons">
          <Button
            color="inverse"
            rightIcon
            iconSize="small"
            iconName="pencil"
            onClick={() => {
              setShowFilters((curr) => !curr);
            }}
          >
            {t("iTd.reports.customize")}
          </Button>
          <Button
            disabled={isDisabledCreateReport()}
            onClick={handleOnGenerateReport}
          >
            {isRequestReport === configuration.reportType ? (
              <Spinner />
            ) : (
              t("iTd.reports.generate")
            )}
          </Button>
        </div>
        <ReportFilters
          showFilters={showFilters}
          currentFilters={currentFilters}
          onUpdateFilters={handleOnUpdateFilters}
          filtersConfig={configuration?.details}
          isSelectEmpty={isSelectEmpty}
          setIsSelectEmpty={setIsSelectEmpty}
        />
      </Card.Footer>
    </Card>
  );
};

export default observer(ReportCard);
