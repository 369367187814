import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";

import SimplePage from "components/common/SimplePage/SimplePage";
import Loader from "components/Loader/Loader";
import ReportCard from "components/ReportCard";
import useReports from "customHooks/useReports";
import { useStore } from "store/storeUtils";
import ReportsDisabledCard from "../../components/Cards/ReportsDisabledCard";
import "./reports.scss";

// Optional Props
interface ReportsProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

const Reports: React.FC<ReportsProps> = observer(() => {
  const { t } = useTranslation();
  const store = useStore();

  const {
    isLoadingReports,
    configurations,
    isRequestReport,
    isEnabled,
    generateReport,
  } = useReports();

  const getReportsContent = () => {
    if (isLoadingReports || !store.projectsFilterOptions)
      return (
        <div className="reports-loading">
          <Loader hAlign="center" vAlign="center" />
        </div>
      );
    if (
      !isEnabled ||
      !configurations?.length ||
      !store.projectsFilterOptions.length
    )
      return <ReportsDisabledCard />;
    if (configurations)
      return (
        <div className="reports">
          {configurations.map((configuration) => (
            <ReportCard
              key={configuration.reportType}
              isRequestReport={isRequestReport}
              onGenerateReport={generateReport}
              configuration={configuration}
            />
          ))}
        </div>
      );
    return null;
  };

  return (
    <SimplePage
      title={t("iTd.reports.title")}
      description={t("iTd.reports.description")}
    >
      {getReportsContent()}
    </SimplePage>
  );
});

export default React.memo(Reports);
