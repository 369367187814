import { useTranslation } from "@tecma/i18n";
import React from "react";
import { ReactSVG } from "react-svg";
import toolIcon from "../../icons/toolIcon.svg";
import "./toolsDrawerContent.scss";

// Optional Props
interface ToolsDrawerItemProps {
  // "data-testid"?: string;
  title?: string;
  status?: string;
  // enabledTools?: Array<any>;
}

// use the optional prop interface to define the default props
const defaultProps: ToolsDrawerItemProps = {
  // "data-testid": "tecma-ToolsDrawerItem",
};

const ToolsDrawerItem: React.FC<ToolsDrawerItemProps> = ({ title, status }) => {
  const { t } = useTranslation();
  return (
    <div className="tools-drawer-item">
      <div className="tools-drawer-item-left-side">
        <ReactSVG className="icon" src={toolIcon} />
        <div className="title">{title}</div>
      </div>

      <div className={`label ${status}`}>
        <div className="text">{t(`iTd.asset.${status}`)}</div>
      </div>
    </div>
  );
};

ToolsDrawerItem.defaultProps = defaultProps;

export default React.memo(ToolsDrawerItem);
