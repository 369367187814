/* eslint-disable prettier/prettier */
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation, Trans } from "@tecma/i18n";
import { useStore } from "store/storeUtils";
import { observer } from "mobx-react";
import { useLazyQuery } from "@apollo/client";
import { getBssUrl } from "utils/getProjectUrls";
import Client from "gqlclient/Client";
import house from "icons/house.svg";
import dollar from "icons/dollar.svg";
import appointments from "icons/appointments.svg";
import users from "icons/users.svg";
import emptyStateGraphic from "icons/emptyStateGraphic.svg";
import emptyStateAsset from "icons/emptyStateAsset.svg";
import NumberCard from "components/Cards/NumberCard/NumberCard";
import WelcomeCard from "components/Cards/WelcomeCard/WelcomeCard";
import FullImageCard from "components/Cards/FullImageCard/FullImageCard";
import SimplePage from "components/common/SimplePage/SimplePage";
import LeftImageCard, {
  SimpleBody,
} from "components/Cards/LeftImageCard/LeftImageCard";
import ExternalLinkButton from "components/Buttons/ExternalLinkButton/ExternalLinkButton";
import InternalLinkButton from "components/Buttons/InternalLinkButton/InternalLinkButton";
import { Grid } from "@mui/material";
import Capitalize from "utils/Capitalize";
import Carousel from "../../components/Carousel/Carousel";

import "./dashboard.scss";
import EmptyStateCard from "../../components/Cards/EmptyStateCard/EmptyStateCard";
import Loader from "../../components/Loader/Loader";
import { eLearningTiles } from "../../constants/eLearningTiles";

// Optional Props
interface DashboardProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

interface AssetElement {
  title: string;
  description: string;
  imageSrc: string;
  button: ReactElement;
}

// use the optional prop interface to define the default props
const defaultProps: DashboardProps = {
  "data-testid": "tecma-Dashboard",
};

const Dashboard: React.FC<DashboardProps> = observer(() => {
  const store = useStore();
  const { t } = useTranslation();
  const demoProject = store.demoProject;

  const [totalApartmentsCount, setTotalApartmentsCount] = useState(undefined);
  const [apartmentsSoldCount, setApartmentsSoldCount] = useState(undefined);
  const [scheduledAppointmentsCount, setScheduledAppointmentsCount] =
    useState(undefined);
  const [leadsCount, setLeadsCount] = useState(undefined);
  const [assets, setAssets] = useState<Array<any>>([]);
  // const [emptyState, setEmptyState] = useState(true);

  const [loadApartmentsCount, apartmentsCount] = useLazyQuery(
    Client.GET_APARTMENTS_COUNT,
  );
  const [loadApartmentsSold, apartmentsSold] = useLazyQuery(
    Client.GET_APARTMENTS_SOLD_COUNT,
  );

  const [loadScheduledAppointments, scheduledAppointments] = useLazyQuery(
    Client.GET_CALENDAR_APPOINTMENTS_COUNT,
  );

  const [loadLeads, leads] = useLazyQuery(Client.GET_CLIENTS_COUNT);

  React.useEffect(() => {
    if (!store.emptyState) {
      loadApartmentsCount(
        Client.GET_APARTMENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadApartmentsSold(
        Client.GET_APARTMENTS_SOLD_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadScheduledAppointments(
        Client.GET_CALENDAR_APPOINTMENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadLeads(
        Client.GET_CLIENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
    }
  }, [store.emptyState]);

  const getProjects = (projects: Array<any>) => {
    return projects.map((project) => {
      const heroImageSrc = `${
        process.env.REACT_APP_BUCKET_BASEURL
      }/initiatives/${
        project.displayName
      }/businessplatform/hero-image.jpg?v=${new Date().getDay()}`;
      return {
        title: project.displayName,
        street: project.storeAddress,
        imageSrc: heroImageSrc,
        button: (
          <ExternalLinkButton
            title={t("iTd.dashboard.go")}
            to={project?.enabledTools ? getBssUrl(project) : ""}
            white
          />
        ),
      };
    });
  };

  useEffect(() => {
    if (!apartmentsCount.loading && apartmentsCount.data) {
      setTotalApartmentsCount(apartmentsCount.data.getApartmentsCount);
    }
  }, [apartmentsCount]);

  useEffect(() => {
    if (!apartmentsSold.loading && apartmentsSold.data) {
      setApartmentsSoldCount(apartmentsSold.data.getApartmentSoldCount);
    }
  }, [apartmentsSold]);

  useEffect(() => {
    if (!scheduledAppointments.loading && scheduledAppointments.data) {
      setScheduledAppointmentsCount(
        scheduledAppointments.data.getCalendarAppointmentCounts.managed,
      );
    }
  }, [scheduledAppointments]);

  useEffect(() => {
    if (!leads.loading && leads.data) {
      setLeadsCount(leads.data.getClientCount);
    }
  }, [leads]);

  useEffect(() => {
    if (store.actualUserAuthorizedProjectsId && store.actualProjects) {
      setAssets(
        getProjects(
          store.actualProjects.filter((project) =>
            store.actualUserAuthorizedProjectsId.includes(project.id),
          ),
        ),
      );
    }
  }, [store.actualUserAuthorizedProjectsId, store.actualProjects]);

  const getDashboardContent = () => {
    if (!store.loggedUser?.id) {
      return (
        <div className="dashboard-loading">
          <Loader hAlign="center" vAlign="center" />
        </div>
      );
    }
    return (
      <>
        <Grid className="business-overview" container spacing={2}>
          <Grid
            item
            xs={12}
            md={8}
            className={classNames(
              "dashboard-info-cards",
              store.emptyState && "empty-state",
            )}
          >
            {store.emptyState ? (
              <EmptyStateCard
                iconSrc={emptyStateGraphic}
                title={t("iTd.emptyState.noDataTitle", undefined)}
                text={t("iTd.emptyState.text", undefined)}
                ctaLabel={t("iTd.emptyState.cta", undefined)}
              />
            ) : (
              <>
                <InternalLinkButton
                  title={t("iTd.navbar.businessOverview")}
                  to="/business-overview"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <NumberCard
                      title={t("iTd.dashboard.apt")}
                      icon={house}
                      number={totalApartmentsCount}
                      isLoading={apartmentsCount.loading}
                      dataNotAvailable={t("iTd.dataNotAvailable")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberCard
                      title={t("iTd.dashboard.aptSold")}
                      icon={dollar}
                      number={apartmentsSoldCount}
                      isLoading={apartmentsSold.loading}
                      dataNotAvailable={t("iTd.dataNotAvailable")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberCard
                      title={t("iTd.dashboard.scheduled")}
                      icon={appointments}
                      number={scheduledAppointmentsCount}
                      isLoading={scheduledAppointments.loading}
                      dataNotAvailable={t("iTd.dataNotAvailable")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberCard
                      title={t("iTd.business.totalUsers")}
                      icon={users}
                      number={leadsCount}
                      isLoading={leads.loading}
                      dataNotAvailable={t("iTd.dataNotAvailable")}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4} className="welcome-container">
            <WelcomeCard
              title={
                <Trans
                  i18nKey="iTd.dashboard.welcomeBack"
                  values={{
                    userFirstName: Capitalize(
                      store.loggedUser?.firstName?.toLowerCase() || "",
                    ),
                    userLastName: Capitalize(
                      store.loggedUser?.lastName?.toLowerCase() || "",
                    ),
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <div className="assets-management">
          <InternalLinkButton
            title={t("iTd.dashboard.asset")}
            to="/assets-management"
          />
          <div>
            {store.emptyState ? (
              <EmptyStateCard
                iconSrc={emptyStateAsset}
                title={t("iTd.emptyState.noAssetTitle", undefined)}
                text={t("iTd.emptyState.text", undefined)}
                ctaLabel={t("iTd.emptyState.cta", undefined)}
              />
            ) : assets?.length > 1 ? (
              <Carousel>
                {assets.map((el: AssetElement) => (
                  <FullImageCard key={el.title + el.description} {...el} />
                ))}
              </Carousel>
            ) : assets?.length === 1 ? (
              <FullImageCard {...assets[0]} />
            ) : (
              ""
            )}
          </div>
        </div>

        <InternalLinkButton
          title={t("iTd.navbar.elearning")}
          to="/e-learning"
        />

        {eLearningTiles(demoProject)
          .filter((tile) => tile.key !== "training")
          .map((tile) => (
            <LeftImageCard key={tile.key} imageSrc={tile.image}>
              <SimpleBody
                title={t(`iTd.dashboard.${tile.key}.title`)}
                description={t(`iTd.dashboard.${tile.key}.description`)}
                buttons={
                  <ExternalLinkButton
                    title={t(`iTd.dashboard.${tile.key}.buttonText`)}
                    to={tile.url}
                  />
                }
              />
            </LeftImageCard>
          ))}
      </>
    );
  };

  return (
    <SimplePage
      title={t("iTd.navbar.dashboard")}
      description={t("iTd.dashboard.today")}
    >
      {getDashboardContent()}
    </SimplePage>
  );
});

Dashboard.defaultProps = defaultProps;

export default Dashboard;
