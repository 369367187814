import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "@tecma/i18n";
import { useStore } from "store/storeUtils";
import { ApolloConsumer, useLazyQuery } from "@apollo/client";
import Client from "gqlclient/Client";
import { Grid } from "@mui/material";
import _ from "lodash";
import PieChart from "components/Charts/PieChart/PieChart";
import ValueCard from "components/Cards/ValueCard/ValueCard";
import users from "icons/users.svg";
import user from "icons/user.svg";
import appointments from "icons/appointments.svg";
import appointmentsCanceled from "icons/appointmentsCanceled.svg";
import emptyStatePie from "icons/emptyStatePie.svg";
import emptyStateTable from "icons/emptyStateTable.svg";
import getClientsPieChartData from "utils/getClientsPieChartData";
import getAppointmentsPieChartData from "utils/getAppointmentsPieChartData";
import noDataFiller from "utils/fillEmptyData";
import NumberCard from "../../components/Cards/NumberCard/NumberCard";
import TitleCard from "../../components/Cards/TitleCard/TitleCard";
import GenericTable from "../../components/common/Table/GenericTable";
import {
  ChipCell,
  DateCell,
  EmptyCell,
  IconCell,
  NumericCell,
  ProjectCell,
} from "../../components/common/Table/TableRender";
import clientStatusColorMap from "./clientStatusColorMap";
import EmptyStateCard from "../../components/Cards/EmptyStateCard/EmptyStateCard";

// Optional Props
// interface BusinessOverviewApartmentsProps {}

// // use the optional prop interface to define the default props
// const defaultProps: BusinessOverviewApartmentsProps = {};

const BusinessOverviewClients: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();

  const [usersPieChartData, setUsersPieChartData] = useState<any>(undefined);
  const [appointmentsPieChartData, setAppointmentsPieChartData] =
    useState<any>(undefined);
  const [totalClients, setTotalClients] = useState<any>(undefined);
  const [totalAppointments, setTotalAppointments] = useState<any>(undefined);
  const [managedAppointments, setManagedAppointments] =
    useState<any>(undefined);
  const [canceledAppointments, setCanceledAppointments] =
    useState<any>(undefined);
  const [clientsCountNumber, setClientsCount] = useState<any>(undefined);

  const clientsTableColumns = [
    {
      title: t("iTd.business.users"),
      field: "name",
      render: (rowData: any) => (
        <IconCell
          iconSrc={user}
          text={
            rowData.firstName && rowData.lastName
              ? `${rowData.firstName} ${rowData.lastName}`
              : "-"
          }
        />
      ),
      searchable: true,
      sorting: true,
    },
    {
      title: t("iTd.business.asset"),
      field: "project_id",
      render: (rowData: any) =>
        rowData.project_id ? (
          <ProjectCell
            projects={[...store.projects]}
            projectId={rowData.project_id}
          />
        ) : (
          <EmptyCell />
        ),
      sorting: true,
      searchable: true,
    },
    {
      title: t("iTd.business.registrationDate"),
      field: "createdOn",
      render: (rowData: any) =>
        rowData.createdOn ? (
          <DateCell date={rowData.createdOn} />
        ) : (
          <EmptyCell />
        ),
      searchable: false,
      sorting: true,
    },
    {
      title: t("iTd.business.Status"),
      field: "status",
      render: (rowData: any) =>
        rowData.status ? (
          <ChipCell
            label={t(`iTd.business.${rowData.status}`)}
            color={clientStatusColorMap[rowData.status]}
          />
        ) : (
          <EmptyCell />
        ),
      searchable: true,
      sorting: true,
    },
    {
      title: t("iTd.business.managed"),
      field: "nAppuntamenti",
      type: "numeric",
      render: (rowData: any) =>
        rowData.nAppuntamenti ? (
          <NumericCell value={rowData.nAppuntamenti} />
        ) : (
          <EmptyCell />
        ),
      searchable: false,
      sorting: true,
    },
    {
      title: t("iTd.business.requestedQuotes"),
      field: "nPreventivi",
      type: "numeric",
      render: (rowData: any) =>
        rowData.nPreventivi ? (
          <NumericCell value={rowData.nPreventivi} />
        ) : (
          <EmptyCell />
        ),
      sorting: true,
    },
  ];

  const [loadClients, clients] = useLazyQuery(Client.GET_CLIENTS_COUNT);
  const [loadAppointmentsCounts, appointmentsCounts] = useLazyQuery(
    Client.GET_CALENDAR_APPOINTMENTS_COUNT,
  );

  const [loadUsersStatus, usersStatus] = useLazyQuery(
    Client.GET_CLIENTS_COUNT_BY_STATUS,
  );

  const [loadAppointmentsInfo, appointmentsInfo] = useLazyQuery(
    Client.GET_ACCESS_INFORMATION,
  );

  const [loadCalendarAppointmentsInfo, calendarAppointmentsInfo] = useLazyQuery(
    Client.GET_CALENDAR_APPOINTMENTS_COUNT,
  );

  React.useEffect(() => {
    if (!store.emptyState) {
      loadClients(
        Client.GET_CLIENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadAppointmentsCounts(
        Client.GET_CALENDAR_APPOINTMENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadUsersStatus(
        Client.GET_CLIENTS_COUNT_BY_STATUS_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadCalendarAppointmentsInfo(
        Client.GET_CALENDAR_APPOINTMENTS_COUNT_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
      loadAppointmentsInfo(
        Client.GET_ACCESS_INFORMATION_DEFAULT_OPTIONS(
          store.actualUserAuthorizedProjectsId,
        ),
      );
    }
  }, [store.emptyState]);

  useEffect(() => {
    if (usersStatus.data) {
      const chartData = getClientsPieChartData(
        usersStatus.data.getClientCountByStatus,
      );
      setUsersPieChartData(chartData);
    }
  }, [usersStatus.data]);

  useEffect(() => {
    if (appointmentsInfo.data) {
      const chartData = getAppointmentsPieChartData(
        appointmentsInfo.data.getAccessInformation,
      );
      setAppointmentsPieChartData(chartData);
      const total = appointmentsInfo.data.getAccessInformation?.find(
        (appointment: any) =>
          appointment.typology === "Appuntamenti Gestiti Totali",
      )?.number;
      setTotalAppointments(total);
    }
  }, [appointmentsInfo.data]);

  useEffect(() => {
    if (calendarAppointmentsInfo.data) {
      setManagedAppointments(
        calendarAppointmentsInfo.data.getCalendarAppointmentCounts.managed,
      );
      setCanceledAppointments(
        calendarAppointmentsInfo.data.getCalendarAppointmentCounts.canceled,
      );
    }
  }, [calendarAppointmentsInfo.data]);

  useEffect(() => {
    if (clients.data) {
      setTotalClients(clients.data.getClientCount);
    }
  }, [clients.data]);

  useEffect(() => {
    if (appointmentsCounts.data) {
      const scheduledAppointmentsData =
        appointmentsCounts.data.getCalendarAppointmentCounts;
      setManagedAppointments(scheduledAppointmentsData.managed);
      setCanceledAppointments(scheduledAppointmentsData.canceled);
    }
  }, [appointmentsCounts.data]);

  useEffect(() => {
    if (clients.data) {
      setClientsCount(clients.data.getClientCount);
    }
  }, [clients.data]);

  const getData = useCallback(async (query: any, client: any) => {
    let sorting;
    let searching;

    if (query.search) {
      const searchableFields = clientsTableColumns.filter(
        (el) => el.searchable,
      );
      searching = searchableFields.flatMap((el) => {
        if (el.field === "name") {
          return [{ key: "fullName", value: query.search }];
        }
        return [{ key: el.field, value: query.search }];
      });
    }
    if (query.orderBy) {
      const sortProperty = clientsTableColumns.find(
        (el) => el.field === query.orderBy.field,
      );
      if (sortProperty && sortProperty.sorting) {
        if (sortProperty.field === "name") {
          sorting = [
            {
              key: "firstName",
              order: query.orderDirection === "asc" ? 1 : -1,
            },
            {
              key: "lastName",
              order: query.orderDirection === "asc" ? 1 : -1,
            },
          ];
        } else {
          sorting = [
            {
              key: sortProperty.field,
              order: query.orderDirection === "asc" ? 1 : -1,
            },
          ];
        }
      }
    }

    const { variables } = Client.GET_ALL_CLIENTS_DEFAULT_OPTIONS(
      store.actualUserAuthorizedProjectsId,
      query.page + 1,
      query.pageSize,
      sorting,
      searching,
    );

    const res = await client.query({
      query: Client.GET_ALL_CLIENTS,
      variables,
    });

    const { elements, total, page } = res.data.getAllClients;
    if (total > 0) {
      return {
        data: _.cloneDeep(elements),
        page: page - 1,
        totalCount: total,
      };
    }
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }, []);

  return (
    <Grid container spacing={2}>
      {!store.emptyState && (
        <>
          <Grid item sm={12} md={4}>
            <NumberCard
              title={t("iTd.business.totalUsers")}
              icon={users}
              number={clientsCountNumber}
              isLoading={clients.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <NumberCard
              title={t("iTd.business.managed")}
              icon={appointments}
              number={managedAppointments}
              isLoading={appointmentsCounts.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <NumberCard
              title={t("iTd.business.canceled")}
              icon={appointmentsCanceled}
              number={canceledAppointments}
              isLoading={appointmentsCounts.loading}
              dataNotAvailable={t("iTd.dataNotAvailable")}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="card-container">
              <TitleCard title={t("iTd.business.users")} />
              {store.emptyState ? (
                <EmptyStateCard
                  iconSrc={emptyStatePie}
                  title={t("iTd.emptyState.noDataTitle", undefined)}
                  text={t("iTd.emptyState.text", undefined)}
                  ctaLabel={t("iTd.emptyState.cta", undefined)}
                />
              ) : (
                <>
                  <ValueCard
                    title={t("iTd.business.totalUsers")}
                    value={totalClients}
                    dataNotAvailable={t("iTd.dataNotAvailable")}
                    isNotValid={!totalClients}
                  />
                  <PieChart
                    data={usersPieChartData}
                    loading={clients.loading}
                  />
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="card-container clients-pie-chart">
              <TitleCard title={t("iTd.business.appointments")} />
              {store.emptyState ? (
                <EmptyStateCard
                  iconSrc={emptyStatePie}
                  title={t("iTd.emptyState.noDataTitle", undefined)}
                  text={t("iTd.emptyState.text", undefined)}
                  ctaLabel={t("iTd.emptyState.cta", undefined)}
                />
              ) : (
                <>
                  <ValueCard
                    title={t("iTd.business.totalAppointments")}
                    value={totalAppointments}
                    dataNotAvailable={t("iTd.dataNotAvailable")}
                    isNotValid={!totalAppointments}
                  />
                  <PieChart
                    data={appointmentsPieChartData}
                    loading={clients.loading}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="card-container">
          {store.emptyState ? (
            <>
              <TitleCard title={t("iTd.business.lastUser")} />
              <EmptyStateCard
                iconSrc={emptyStateTable}
                title={t("iTd.emptyState.noDataTitle", undefined)}
                text={t("iTd.emptyState.text", undefined)}
                ctaLabel={t("iTd.emptyState.cta", undefined)}
              />
            </>
          ) : (
            <ApolloConsumer>
              {(client) => (
                <GenericTable
                  columns={clientsTableColumns}
                  title={t("iTd.business.lastUser")}
                  pageSize={5}
                  pageSizeOptions={[5, 10, 20]}
                  search
                  key="name"
                  paging
                  data={(query: any) => getData(query, client)}
                />
              )}
            </ApolloConsumer>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default React.memo(BusinessOverviewClients);
