import React from "react";
import "./EmptyCard.scss";

// Optional Props
interface EmptyCardProps {
  children?: any;
}

// use the optional prop interface to define the default props
const defaultProps: EmptyCardProps = {};

const EmptyCard: React.FC<EmptyCardProps> = ({ children }) => {
  return <div className="empty-card-root">{children}</div>;
};

EmptyCard.defaultProps = defaultProps;

interface SimpleBodyProps {
  title?: string;
  description?: string;
  buttons?: any;
}

export const SimpleBody: React.FC<SimpleBodyProps> = ({
  title,
  description,
  buttons,
}) => {
  return (
    <div className="empty-card-body">
      <div>
        <div className="empty-card-title">{title}</div>
        <div className="empty-card-description">{description}</div>
      </div>
      {buttons}
    </div>
  );
};

export default React.memo(EmptyCard);
