/* eslint-disable prettier/prettier */
import { memo, useCallback } from "react";
import { useTranslation } from "@tecma/i18n";
import Pagination from "@mui/material/Pagination";
import Dropdown from "../../Inputs/Dropdown/Dropdown";

interface CustomPaginationProps {
  page?: any;
  rowsPerPage?: any;
  count?: any;
  onPageChange?: any;
  rowsPerPageOptions?: any;
  onRowsPerPageChange?: any;
}

const defaultProps: CustomPaginationProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
  page,
  rowsPerPage,
  count,
  onPageChange,
  rowsPerPageOptions,
  onRowsPerPageChange,
}) => {
  const { t } = useTranslation();

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      onPageChange(event, value - 1);
    },
    [],
  );
  const handleElementsPerPageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: any) => {
      onRowsPerPageChange(event, value);
    },
    [],
  );

  const pageCount = Math.ceil(count / rowsPerPage);

  return (
    <div className="custom-pagination">
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={handlePageChange}
      />
      <Dropdown
        renderValue={(value) => <span>{`${value} / ${t("iTd.page")}`}</span>}
        direction="top"
        value={rowsPerPage}
        onChange={handleElementsPerPageChange}
        options={rowsPerPageOptions}
        type="pagination"
      />
    </div>
  );
};

export default memo(CustomPagination);
