import { ProjectObject } from "store/store";
import { getBssUrl } from "utils/getProjectUrls";

export const eLearningTiles = (demoProject: ProjectObject | undefined) => {
  return [
    {
      key: "documentation",
      url: "http://resources.tecmasolutions.com/",
      image: `${process.env.REACT_APP_BUCKET_BASEURL}/iTd/img/docu.jpg`,
    },
    {
      key: "interactive",
      url: demoProject ? getBssUrl(demoProject) : "",
      image: `${process.env.REACT_APP_BUCKET_BASEURL}/iTd/img/demo.jpg`,
    },
    {
      key: "training",
      url: "https://www.tecmasolutions.com/digital-real-estate-university-2023/",
      image: `${process.env.REACT_APP_BUCKET_BASEURL}/iTd/img/training.jpg`,
    },
  ];
};
