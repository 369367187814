import React from "react";
import "./TitleCard.scss";

// Optional Props
interface TitleCardProps {
  title?: string;
  rightPart?: any;
  isVisible?: boolean;
}

// use the optional prop interface to define the default props
const defaultProps: TitleCardProps = {};

const TitleCard: React.FC<TitleCardProps> = ({
  title,
  rightPart,
  isVisible,
}) => {
  return (
    <div className="title-card">
      <div className="title-card-title">{title}</div>
      <div className="title-card-action">{isVisible ? rightPart : null}</div>
    </div>
  );
};

TitleCard.defaultProps = defaultProps;

export default React.memo(TitleCard);
