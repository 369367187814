import { useLazyQuery } from "@apollo/client";
import { Button, Card, Icon, Input, Spinner } from "@tecma/ds";
import { useTranslation } from "@tecma/i18n";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  useFormHandler,
  SubmitHandler,
} from "@tecma/ds/lib/utils/hooks/useFormHandler";

import { AccountManagerDrawer } from "components/AccountManagerDrawer";
import type { AccountFields } from "components/AccountManagerDrawer/AccountManagerDrawer";
import { AccountManagerTable } from "components/AccountManagerTable";
import SimplePage from "components/common/SimplePage/SimplePage";
import { getRoleByValue } from "constants/role";
import useFieldValidator from "customHooks/useFieldValidator";
import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import type { Role } from "types/role";

import "./account-manager.scss";

interface AccountManagerProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

export type BackOfficeUser = {
  createdOn: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  notificationLanguage: string;
  project_ids: string[];
  role: Role;
  tel?: string;
};

const AccountManager: React.FC<AccountManagerProps> = observer(() => {
  const { t } = useTranslation();
  const store = useStore();
  const [isAccountManagerDrawerOpen, setIsAccountManagerDrawerOpen] =
    useState(false);
  const [userData, setUserData] = useState<BackOfficeUser>();
  const [fields, setFields] = useState<AccountFields>();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [getBackOfficeUsersITD, backOfficeUsersITD] = useLazyQuery(
    Client.GET_BACK_OFFICE_USERS_ITD,
  );
  const showSearchResults =
    backOfficeUsersITD?.called && !backOfficeUsersITD?.loading;
  const { getFieldError } = useFieldValidator();
  const { registerWithDefaultValidations, handleSubmit, errors } =
    useFormHandler({
      mode: "onBlur",
    });

  const searchContainerClassList = classNames("search-container", {
    error: errors?.email,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const searchAccount: SubmitHandler<AccountFields> = (formData) => {
    if (Object.keys(errors).length) {
      return;
    }
    getBackOfficeUsersITD(
      Client.GET_BACK_OFFICE_USERS_ITD_DEFAULT_OPTIONS(
        store.demoProject?.id || "",
        {
          email: formData.email?.trim(),
          project_ids: store.actualProjectsIds,
        },
        // page + 1,
        // pageSize,
      ),
    )
      .then((res) => {
        if (res.data?.getBackOfficeUsersITD) {
          setTableData(
            res.data.getBackOfficeUsersITD.map((user: BackOfficeUser) => ({
              ...user,
              project_ids: user.project_ids.filter(
                (id: string) => !store.allDemoProjectsId?.includes(id),
              ),
            })),
          );
        }
      })
      .catch((err) => {
        setTableData([]);
      });
  };
  const convertToAccountFields = (user: BackOfficeUser): AccountFields => {
    const language = {
      label: t(`iTd.language.${user.notificationLanguage}`),
      value: user.notificationLanguage,
    };
    const assets = store.projectsFilterOptions?.filter((project) =>
      user.project_ids.some((id) => project.value.includes(id)),
    );
    return {
      name: user.firstName,
      surname: user.lastName,
      email: user.email,
      phone: user.tel || "",
      language,
      role: getRoleByValue(user.role),
      assets,
    };
  };
  const onClickEditAccount = (user: BackOfficeUser) => {
    setFields(convertToAccountFields(user));
    setUserData(user);
    setIsAccountManagerDrawerOpen(true);
  };
  const onAccountManagerDrawerClose = () => {
    setFields(undefined);
    setUserData(undefined);
  };

  // TODO: use only if endpoint support pagination
  // React.useEffect(() => {
  //   if (backOfficeUsersITD.called) {
  //     searchAccount();
  //   }
  // }, [page, pageSize]);

  return (
    <>
      <SimplePage
        title={t("iTd.accountManager.title")}
        description={t("iTd.accountManager.description")}
      >
        <div className="account-manager">
          <Card fluid>
            <Card.Header>
              <div className="header-content">
                <p className="title">
                  {t("iTd.accountManager.searchAccount.title")}
                </p>
                <p className="description">
                  {t("iTd.accountManager.searchAccount.description")}
                </p>
              </div>
              <Button
                iconName="plus"
                onClick={() => setIsAccountManagerDrawerOpen(true)}
                color="transparent"
              >
                {t("iTd.accountManager.addAccount")}
              </Button>
            </Card.Header>
            <Card.Content className="content">
              <form
                onSubmit={handleSubmit(searchAccount)}
                className={searchContainerClassList}
              >
                <Input
                  label={t("iTd.accountManager.field.email.label")}
                  placeholder={t("iTd.accountManager.field.email.placeholder")}
                  status={errors?.email ? "error" : undefined}
                  helpText={getFieldError("email", errors)}
                  {...registerWithDefaultValidations("email", {
                    required: true,
                  })}
                />
                <Button
                  onClick={handleSubmit(searchAccount)}
                  iconName={!backOfficeUsersITD.loading && "search"}
                >
                  {backOfficeUsersITD.loading && (
                    <Spinner type="dotted-circle" size="small" />
                  )}
                  {t("iTd.genericTable.search")}
                </Button>
              </form>
              {showSearchResults ? (
                tableData.length > 0 ? (
                  <AccountManagerTable
                    tableData={tableData}
                    loading={backOfficeUsersITD.loading}
                    hideActions={tableData.length > 1}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onClickEditAccount={onClickEditAccount}
                  />
                ) : (
                  <div className="no-search-results">
                    <Icon iconName="exclamation-circle" size="large" />
                    <h3>{t("iTd.accountManager.table.noResults.title")}</h3>
                    <p>{t("iTd.accountManager.table.noResults.description")}</p>
                  </div>
                )
              ) : null}
            </Card.Content>
          </Card>
        </div>
      </SimplePage>
      <AccountManagerDrawer
        isOpen={isAccountManagerDrawerOpen}
        setIsOpen={setIsAccountManagerDrawerOpen}
        accountFields={fields}
        onClose={onAccountManagerDrawerClose}
        onSuccess={handleSubmit(searchAccount)}
        user={userData}
      />
    </>
  );
});

export default React.memo(AccountManager);
