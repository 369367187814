import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const ScrollToTop: React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    // Riporto l'utente in testa alla pagina solo se non ha premuto il tasto indietro
    if (navigationType !== "POP") {
      // L'elemento con l'id body-container è l'elemento che fa scrollare il contenuto
      const bodyContainer = document.getElementById("body-container");
      if (bodyContainer) {
        bodyContainer.scrollTo(0, 0);
      }
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
